@import "variables";

/*
common styles
*/

* {
  outline: none;
  font-family: @roboto;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1, h2, h3,
ul,
p:last-child {
  margin-bottom: 0;
}

li {
  list-style: none;
}

.textWrapper {
  font-weight: 800;
  font-size: 6vw;
  color: #fff;
}

.wordContainer {
  //float: left;
  display: inline-block;
  overflow: hidden;
}

/* Then, set the transform */
.word {
  transform: translateY(115%);
  opacity: 0;
}

.menu-item {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  color: @dark-grey;
  transition: 0.25s ease-in-out;
  &:hover {
    color: @green;
    transition: 0.25s ease-in-out;
  }
}

.ml12 {
  opacity: 0;
  position: relative;
  font-weight: 900;
  font-size: 4em;
}

.ml12 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}

.ml12 .letter {
  display: inline-block;
  line-height: 1em;
  transform-origin: 0 0;
  opacity: 0;
}

.title {
  position: relative;
  font-size: 36px;
  line-height: 36px;
  font-weight: 500;
  color: @dark-grey;

  text-transform: uppercase;

  &__word {
    position: relative;
    display: inline-block;
    padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.1em;
    overflow: hidden;
  }

  &__letter {
    display: inline-block;
    line-height: 1em;
    transform-origin: 0 0;
  }

  //&__word {
  //  display: inline-block;
  //  letter-spacing: -4px;
  //}
  //
  //&__letter {
  //  display: inline;
  //}

  &_left-line {

    .initial-letter {
      position: absolute;
      z-index: -1;
      left: 20px;
      top: 0;
      transform: translate(-95%, -50%);
    }
    &:before {
      content: '';
      position: absolute;
      top: 21px;
      right: 100%;
      z-index: 2;
      display: block;
      width: 98px;
      height: 1px;
      margin-right: 26px;
      background: @green;
    }
  }

  &_left-right {
    display: inline-block;
    padding-right: 110px;

    .initial-letter {
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(60%, -50%);
    }
    &:before {
      content: '';
      position: absolute;
      top: 21px;
      right: 0;
      z-index: 2;
      display: block;
      width: 98px;
      height: 1px;
      margin-left: 11px;
      background: @green;
    }
  }

  &_center {
    text-align: center;
    z-index: 3;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 3;
      display: block;
      width: 1px;
      height: 98px;
      background-color: @green;
      transform: translateX(-50%);
    }

    .initial-letter {
      position: absolute;
      top: 0%;
      left: 50%;
      z-index: -1;
      height: 140px;
      color: @white;
      transform: translate(-50%, -50%);
    }
  }

}

.hide-wrapper {
  display: inline-block;
  overflow: hidden;

  span {
    display: block;
    transform: translateY(100%);
    opacity: 0;
    transition: 1s ease;
  }

}

.visible {
  .hide-wrapper {
    span {
      opacity: 1;
      transform: translateY(0%);
      transiton: 2s ease-in-out;
    }
  }
}

.service-title {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
  color: @dark-grey;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 245px;
  height: 48px;

  cursor: pointer;

  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  text-align: center;

  text-decoration: none;
  &_white {
    background: @white;
    border: 1px solid @white;
    color: #323a45;
    transition: 0.25s ease;

    &:hover {
      background: @green;
      border-color: @green;
      color: @white;
      transition: 0.25s ease;
    }
  }

  &_green {
    border: 1px solid @green;
    color: @green;
    background: #fff;
    transition: 0.25s ease-in-out;

    &:hover {
      background: @green;
      color: @white;
      transition: 0.25s ease-in-out;
    }
  }

  &_green-filled {
    border: 1px solid @green;
    color: @white;
    background: @green;
    transition: 0.25s ease-in-out;

    &:hover {
      border-color: @white;
      background: #ffffff !important;
      color: @green;
      transition: 0.25s ease-in-out;
    }
  }
}

.initial-letter {
  max-height: 143px;

  font-size: 200px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f0f0f0;
}

.text {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.44;
  letter-spacing: normal;
  color: @dark-grey;

  p + p {
    margin-top: 20px;
  }

  &_left {
    text-align: left;
  }

  &_right {
    text-align: right;
  }
}

.subtitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  text-align: left;
  color: @dark-grey;
}

.slick-slide {
  outline: none;
}

.text-light {
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #797a7d;

  &_16 {
    font-size: 16px;
  }

  &_18 {
    font-size: 18px;
    font-weight: 300;
  }
}

.text-center {
  text-align: center;
}

.link {
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #7baf33;
}

.dots-bg {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    display: block;
    height: 577px;
    max-height: 50%;
    background: url("/public/img/services-bg.png") no-repeat center bottom;
    background-size: cover;
    opacity: 0.4;
  }
}

/*
navigation
*/
.navigation {
  height: 100%;
  &__item {
    a {
      color: @dark-grey;
      &:hover {
        color: @green;
      }
    }
  }
}

body {
  position: relative;
  background: @white;
  overflow-x: hidden;
}

.main {
  position: relative;
  min-height: 100vh;
  padding-bottom: 187px;
}

input {
  display: block;

  background: transparent;
  border: none;
}

/*
navigation
*/
.hidden-container__button.rotate {
  transform: rotate(180deg);
}

.navigation {
  &__button {
    position: relative;
    width: 54px;
    min-width: 54px;
    height: 54px;
    border-radius: 100%;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 24px;

      display: block;
      width: 0;
      height: 0;
      border: 6px solid #d5d5d5;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-right: 0;

      transform: translate(-50%, -50%);
    }

    &_prev {
      //border: solid 1px #d5d5d5;
      transition: 0.25s ease;
      //height: 50px;
      //width: 50px;
      //left: 24px;
      //
      &:after {
        left: 25px;
        border: 6px solid #d5d5d5;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left: 0;
        transition: 0.25s ease;

      }

      &:hover {
        border-color: @green;
        transition: 0.25s ease;
        &:after {
          border-right-color: @green;
          transition: 0.25s ease;
        }
      }
    }

    &_next {
      //right: 24px;
      &:after {
        left: 28px;
        transition: 0.25s ease;

      }

      &:hover:after {
        border-left-color: @green;
        transition: 0.25s ease;
      }
    }
  }

  &__counter {
    font-size: 20px;
    font-weight: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #808184;
    &-current {
      font-size: 26px;
      font-weight: 500;
      color: @dark-grey;
    }
  }
}

.circle-bg {
  fill: rgba(255, 255, 255, 0);
  stroke: rgba(255, 255, 255, 1);
  stroke-width: 2;
  stroke-linecap: butt;
}

.circle-go {
  fill: rgba(255, 255, 255, 0);
  stroke: @green;
  stroke-width: 2;
  stroke-linecap: round;
}

/*
mobile-menu
*/

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  min-height: 100vh;
  height: 100%;
  background: #f0f0f0;
  width: 100%;
  transform: translateY(-100%);
  transition: 0.25s ease;

  &__wrapper {
    padding: 90px 50px 60px;
  }

  .navigation {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding-bottom: 35px;
  }

  .navigation__list {
    //margin-top: 25px;
    width: 33%;
    margin-top: 75px;
  }

  .menu-item {
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
  }

  .telephone {
    padding: 35px 0;
    border-top: 1px solid #e8e8e8;
    justify-content: flex-start;
  }

  &.opened {
    transform: translateY(0);
    transition: 0.5s ease-in-out;
  }

}

/*
header
*/

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 0;
  background: rgba(240, 240, 240, 0.9);

  &__nav {
    a {
      position: relative;
      display: block;
      padding-bottom: 3px;
      overflow: hidden;
      font-size: 20px;
      font-weight: 300;
      line-height: normal;
      color: @dark-grey;

      &:before {
        content: '';
        position: absolute;
        bottom: 0px;
        left: 0%;
        width: 50%;
        height: 3px;
        object-fit: contain;
        background-color: #acd021;

        transform: translateY(100%);
        transition: 0.25s ease-in-out

      }

      &:hover:before {
        transform: translateY(0);
        transition: 0.25s ease-in-out
      }
    }
  }

  &__tel {
    display: flex;
    align-items: center;
  }

  &__burger {
    position: relative;
    z-index: 999;

    width: 38px;
    height: 24px;

    border-top: 1px solid @green;
    border-bottom: 1px solid @green;

    cursor: pointer;

    transition: 0.25s ease;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;

      display: block;
      height: 1px;
      width: 100%;
      background: @green;

      transition: 0.25s ease;

    }

    &.opened {
      border-color: transparent;

      transition: 0.25s ease;

      &:before {
        transform: rotate(45deg);
        transition: 0.25s ease;
      }
      &:after {
        transform: rotate(-45deg);
        transition: 0.25s ease;
      }
    }
  }

}

.telephone {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    font-size: 16px;
    font-weight: 300;
    color: @dark-grey;
    transition: 0.25s ease-in-out;

    span {
      font-size: 20px;
      font-weight: normal;
    }

    &:hover {
      color: @green;
      transition: 0.25s ease-in-out;
    }
  }
}

/*
main banner
*/

.mainbanner {
  position: relative;
  height: 860px;
  //height: 90vh;
  //max-height: 820px;
  //min-height: 820px;
  overflow: hidden;

  &__content,
  .slick-list,
  .slick-track {
    height: 860px;
    //height: 90vh;
    //max-height: 820px;
    //min-height: 820px;
  }

  &__item {
    position: relative;
    z-index: 2;
    //height: 100vh !important;
    min-height: 100%;
    //padding: 168px 0 0px;
    padding: 17vh 0 0;
    background-color: #272a2f;
    overflow: hidden;

    .title,
    .subtitle {
      color: @white;
    }

    .title {
      max-width: 700px;
      text-transform: uppercase;
      transition: transform 1s ease-in-out;
    }

    .subtitle {
      opacity: 0;
      margin: 12px 0 24px;
      transition: 0.25s ease-in-out;
    }

    .button {
      //transform: translateY(15px);
      opacity: 0;
      transition: 0.25s ease-in-out;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: #272a2f;
      opacity: 0.8;
    }
  }

  &__item-full {
    position: absolute;
    top: 50%;
    left: 50%;

    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
    z-index: 0;
    object-fit: cover;
  }

  &__item-small {
    position: absolute;
    bottom: 0;
    left: calc(~ "50% + 15px");

    //width: calc(~ "100% - 30px");
    //height: 528px;
    width: 66%;
    height: 54%;
    //max-height: 54vh;
    object-fit: cover;
    object-position: top center;

    opacity: 0;
    transform: translate(-50%, 0);
    transition: 0.25s ease-in-out;
  }

  &__item-container {
    position: relative;
    z-index: 3;
    height: 100%;
    //padding-bottom: 600px;
  }

  &__navigation {
    position: absolute;
    //top: 160px;
    top: 17vh;
    left: 50%;
    //transform: translateX(-50%);

    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: 585px;

    .slick-dots li {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-bottom: 0px;

      cursor: pointer;
      svg {
        display: block !important;
        width: 60px;
        height: 60px;

        opacity: 0;
        transition: 0.1s ease-in-out;
      }

      &:before {
        content: '';
        position: absolute;
        top: 16.5px;
        left: 16.5px;
        transform: translate(-50%, -50%);
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background: @white;
      }

      &.slick-active {
        svg {
          opacity: 1;
          transition: 0.1s ease-in-out;
        }
      }
    }
  }

  .slick-current {

    .ml12 {
      opacity: 1;
    }

    //.hide-wrapper {
    //  span {
    //    opacity: 1;
    //    //transform: translateY(0%);
    //    transition: 1s ease-in-out;
    //    transition-delay: 1.5s;
    //  }
    //}

    .subtitle {
      opacity: 1;
      //transform: translateY(0%);
      transition: 0.75s ease-in-out;
      transition-delay: 1.75s;
    }

    .button {
      opacity: 1;
      //transform: translateY(0);
      transition: background-color 0.25s ease-in-out, opacity 0.75s ease-in-out 1.75s, transform 1.5s ease-in-out;

    }

    .mainbanner__item-small {
      opacity: 1;
      //transform: translate(-50%, 0px);
      transition: 0.75s ease-in-out;
      transition-delay: 1.75s;
    }
  }
}

/*
left-image
*/

.left-image {
  padding: 144px 0 70px 0px;

  &_green {
    background: @green;

    .title,
    .subtitle {
      max-width: 390px;
      color: @white;
      &_left-line:before {
        background: @white;
      }
    }

    &.animated.visible .initial-letter {
      opacity: 0.4;
    }

  }

  &_reverse {
    padding: 123px 0 144px 0px;
    .left-image__image img {
      width: 50vw;
      max-width: unset;
      max-height: 100%;
    }
  }
  &__image {
    padding-left: 67px;
    img {
      max-width: 100%;
    }
  }

  &__subtitle {
    margin: 24px 0 36px;
  }

  .title {
    margin-bottom: 20px;
  }

}

/*
success
*/

.success {
  position: relative;
  padding: 86px 0;
  overflow-x: hidden;

  .circle-bg {
    stroke: rgba(0, 0, 0, 0.1);
  }

  &__top {
    padding: 0 15px 48px 67px;
  }

  &__navigation {
    display: flex;
    //justify-content: space-between;
    justify-content: center;
    align-items: center;
  }

  &__counter {
    margin: 0 25px;
  }

  &__stories {
    margin-top: 48px;
    .slick-list {
      overflow: visible;
    }

    .slick-track {
      display: flex;
    }
    .slick-track .slick-slide {
      display: flex;
      height: auto;
      max-height: 725px;
    }
  }

  .title {
    padding-left: 67px;
  }
}

.hidden-list {
  &__title {
    position: relative;
    padding-left: 35px;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.36;
    text-align: left;
    color: #71777f;

    cursor: pointer;

    transition: 0.25s ease-in-out;

    .counter {
      position: absolute;
      top: 5px;
      left: 0;
      transform: rotate(-90deg);

      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #7baf33;

      transition: 0.25s ease-in-out;

    }

    &:hover {
      color: #7baf33;
      transition: 0.25s ease-in-out;
      .counter {
        color: #7baf33;
        transition: 0.25s ease-in-out;
      }
    }
  }
  &__item {
    margin-bottom: 12px;

    &.opened {

      .hidden-list__arrow svg {
        transform: scaleY(-1);
      }

      .hidden-list__text {
        max-height: 999px;
        transition: 1s ease;
      }
    }

  }
  &__text {
    max-height: 0px;
    overflow: hidden;
    transition: 0.25s ease;

    margin-top: 12px;
    padding-left: 34px;
  }
  &__arrow {
    cursor: pointer;
  }

}

.hidden-container {
  &__text {
    position: relative;
    max-height: 380px;
    overflow: hidden;

    transition: 0.25s ease;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0), rgba(255, 255, 255, 1));
      background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.001) 0%, #fff 90%, #fff 100%);

      transition: 0.25s ease;
    }
  }

  &__button {
    display: flex;
    justify-content: center;

    svg {
      width: 18px;
      height: 9px;
      cursor: pointer;
      opacity: .5;
    }

    &.hidden {
      display: none;
    }
  }

  &.opened {
    .hidden-container__text {
      max-height: 9999px;

      transition: 0.25s ease;
      &:before {
        visibility: none;
        opacity: 0;

        transition: 0.25s ease;
      }
    }

    .hidden-container__button {
      display: none;
      &.hidden {
        display: flex;
      }
      svg {
        opacity: .5;
      }
    }
  }
}

/* story */

.story {
  display: flex !important;
  overflow: visible;

  .slick-list {
    overflow-x: visible;
  }

  &__list {
    position: absolute;
    bottom: 50px;
    right: 50px;
  }

  &__info {
    max-height: 100%;
    padding-top: 72px;
    padding-bottom: 380px;
    padding-right: 50px;
  }

  &__title {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.18;
    text-align: right;
    color: #323a45;
  }

  &__description {
    margin: 36px 0 96px;
    text-align: right;
    line-height: 1.67;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:nth-child(even) {
      .story__item-img {
        background: #f0f0f0;
        &:before {
          background: #aacd21;
        }
      }
    }
  }

  &__item-img {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 96px;
    height: 96px;
    background-color: #aacd21;

    img {
      max-width: 50px;
      max-height: 50px;
      object-fit: contain;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;
      height: 1px;
      background-color: #f0f0f0;
    }
  }

  &__item-text {
    width: 100%;
    padding: 0 28px 0 80px;

    font-size: 16px;
    font-weight: normal;
    line-height: 1.25;
    text-align: right;
    color: #797a7d;
  }

  &__img {
    max-height: 100%;
    padding-left: 45px;
    img {
      width: 50vw;
      max-width: 50vw;
      height: 725px;
      max-height: 100%;
      //min-height: 100%;
      object-fit: cover;
    }
  }
}

/*
  services
*/

.services {
  padding: 144px 0 48px;
  background: @light-grey;
  overflow-y: hidden;
  &__list {
    margin-top: 180px;
  }

  &__item {
    margin-bottom: 36px;
    overflow: hidden;
  }

  &__item {
    //width: 41.666667%;
  }

  &__title {
    position: relative;
    padding-left: 33px;
    font-size: 24px;
    font-weight: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: @dark-grey;

    text-transform: uppercase;

  }

  &__counter {
    position: absolute;
    top: 6px;
    left: 0;

    transform: rotate(-90deg);
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #323a45;
  }

  &__text {
    padding-left: 12px;
    line-height: 1.67;

  }

  &_desktop {
    .services__title {
      opacity: 0.2;
      transform: translateX(-100%);
      transition: 0.25s ease-in-out;
    }
    .services__text {
      opacity: 0.2;
      transform: translateX(-100%);
      transition: 0.25s ease-in-out;
    }

    .services__item.visible {

      .services__title,
      .services__text {
        opacity: 1;
        transform: translateX(0%);
        transition: transform 1.5s cubic-bezier(0, 0, 0.3, 1), opacity 0.75s ease-in-out 0.75s;
      }

      .services__text {
        transition-delay: 0.5s;
      }
    }
  }

  &__column {
    //padding-left: 13px;
    &:last-child {
      padding-left: 0;
    }
  }

}

/*
key-industries
*/
.key-industries {
  padding: 96px 0 108px;
  background: @green;

  &__title {
    color: @white;
    text-align: center;
  }

  &__container {
    position: relative;
    padding: 72px 67px 0;
  }

  &__image {
    display: block;
    max-width: 50px;
    max-height: 50px;
    object-fit: contain;
    margin: 0 auto 24px;
  }

  &__name {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: @white;
  }

  &__navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    .navigation__button {
      border-color: @white;
      width: 44px;
      height: 44px;
      min-width: 44px;

      &_next {
        &:after {
          border-left-color: @white;
          left: 24px;
        }
        &:hover:after {
          border-left-color: @green-hover;
        }
      }

      &_prev {
        &:after {
          left: 21px;
          border-right-color: @white;
        }
      }
    }

    .circle-go {
      stroke: #68a224;
      stroke-width: 3;
    }

    .circle-bg {
      stroke: @white;
      stroke-width: 3;
    }
  }
}

/*
team
*/

.team {
  position: relative;
  padding: 108px 0;
  padding-bottom: 80px;

  &__subtitle {
    margin: 36px 0 24px;
    margin-bottom: 8px;
  }

  &__container {
    position: relative;
    z-index: 1;
    padding: 0 30px;
  }

  &__main {
    position: relative;
    z-index: 1;

    .slick-track {
      display: flex;
    }

  }

  &__additional {
    position: absolute !important;
    top: calc(~ "50% - 80px");
    right: 140px;

    transform: translateY(-50%);
    z-index: 0;
    //width: 432px;
    width: 312px;
    height: 432px;
    //height: 432px;
    overflow: hidden;
    &-img {
      width: 100%;
      height: 100%;
      height: 432px !important;
      object-fit: cover;
    }
    .slick-list {
      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #000000;
        opacity: 0.6;
        z-index: 1;
      }
    }
  }

  &__navigation {
    position: absolute;
    top: 343px;
    left: 0;
    right: 0;
    //z-index: 99;

    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 60px;
    z-index: 50;
    padding: 0 20px;

    pointer-events: none;

    .navigation__button_prev {
      pointer-events: all;
      transform: translateX(-15px);
    }

    .navigation__button_next {
      pointer-events: all;
      transform: translateX(15px);
    }

    .circle-bg {
      stroke: @light-grey;
    }
  }

  .title {
    line-height: 0.8;
  }
}

/*
member
*/

.member {
  outline: none;

  &.slick-slide {
    height: auto;
  }

  &__wrapper {
    display: flex;
    justify-content: flex-start;
    min-height: 100%;
    padding-top: 48px;
  }
  &__photo {
    position: relative;
    z-index: 1;
    width: 555px;
    max-width: 100%;
    height: 576px;
    margin-bottom: 36px;
    object-fit: cover;
    pointer-events: none;
  }

  &__photo-next {
    display: none;
    //position: absolute;
    //top: 0;
    //left: 70%;
    //z-index: 0;
    //cursor: pointer;
    //
    //background: @green;

    top: calc(~ "50% - 100px");

    transform: translateY(-50%);
    z-index: 0;

    width: 312px;
    height: 432px;
  }

  &__num {
    position: relative;
    display: inline-block;
    height: 144px;
    margin: 0 0 32px auto;
    font-size: 200px;
    font-weight: normal;
    line-height: 0.29;
    text-align: right;
    color: #f0f0f0;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 96px;
      background: @green;
    }
  }

  &__name {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: right;
    color: #323a45;
  }

  &__position {
    margin: 4px 0 258px;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: right;
    color: #323a45;
  }

  &__left {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 290px;
    padding-right: 56px;
  }

  &__right {
    position: relative;
    width: 555px;

  }

  &__blockquote {
    position: relative;
    padding-left: 77px;
    transform: translateX(38%);
    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #71777f;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 64px;
      height: 54px;
      background: url("/public/img/quote.svg") no-repeat center center;
      background-size: contain;
    }
  }

  &__link {
    //position: relative;
    padding-right: 40px;

    position: absolute;
    bottom: 250px;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);
      width: 30px;
      height: 100%;
      background: url("/public/img/arrow.svg") no-repeat center center;
      background-size: 100%;
      transition: 0.25s ease-in-out;
    }

    &:hover:before {
      transform: translate(25%, -50%);
      transition: 0.25s ease-in-out;
    }
  }
}

/*
audience
*/

.audience {
  padding: 84px 0 96px;
  background: #f0f0f0;

  &__item:nth-child(odd) {
    text-align: right;
    .audience__text {
      padding-left: 38px;
    }
  }

  &__item:nth-child(even) {
    .audience__text {
      padding-right: 38px;
    }
  }

  &__item {
    margin-top: 24px;
    margin-top: 48px;

    img {
      max-width: 100%;
    }
  }

  &__name {
    margin: 24px 0 12px;
    font-size: 20px;
    font-weight: normal;
    line-height: 1.5;
    color: @dark-grey;
  }
}

/*
ebrr-cooperation
*/

.ebrr-cooperation {
  padding: 96px 0 108px;
  .title {
    line-height: 0.8;
  }
  &__subtitle {
    max-width: 520px;
    margin: 24px auto 0;
    color: #71777f;
  }

  .grey-card {
    &__image {
      max-width: 175px;
      margin-bottom: 24px;
    }
  }
}

.grey-card {
  max-width: 683px;
  margin: 48px auto 0;
  padding: 48px 55px 56px;
  background: @light-grey;
  text-align: center;
}

/*
reviews
*/

.reviews {
  padding: 108px 0;
  background: #f0f0f0;

  &__container {
    position: relative;
  }

  &__list {
    position: relative;
    z-index: 2;
    margin-top: 28px;
  }

  &__navigation {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
  }
}

.review {
  display: flex !important;

  &__image {
    width: 50%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }

  &__info {
    width: 50%;
    padding: 36px 0 0 44px;
  }

  &__name {
    font-size: 22px;
    font-weight: 500;
    line-height: 1.36;
    color: @dark-grey;
  }

  &__industry {
    position: relative;
    margin: 24px 0;
    padding-right: 64px;
    font-family: 'Roboto Lt';
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
    text-align: left;
    color: #959595;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 64px;
      height: 54px;
      background: url("/public/img/quote-white.svg") no-repeat center center;
      background-size: contain;
    }
  }

  &__position {
    margin: 24px 0 36px;
  }

  &__link {
    position: relative;
    display: inline-block;
    margin-top: 36px;

    &:before {
      content: '';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(5%, -50%);
      width: 30px;
      height: 100%;
      background: url("/public/img/arrow.svg") no-repeat center center;
      background-size: 100%;
      transition: 0.25s ease-in-out;
    }

    &:hover:before {
      transform: translate(25%, -50%);
      transition: 0.25s ease-in-out;
    }
  }
}

/*
cooperation
*/

.cooperation {
  padding: 96px 0 108px;
  padding-bottom: 68px;

  &__subtitle {
    max-width: 650px;
    margin: 24px auto 38px;
  }
}

/*
form
*/

.form {
  &__field {
    box-sizing: border-box;
    position: relative;
    min-height: 48px;
    width: 100%;
    margin-bottom: 24px;
    padding-top: 12px;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      background: @green;
      height: 1px;
      transform: translateX(-100%);
      transition: 0.25s ease;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: #cccccc;
      height: 1px;
    }

    &.error {
      border-bottom-color: #dd2429;

      .form__error {
        display: block;
      }
    }

    input,
    textarea {
      width: 100%;
      height: 36px;
      padding-right: 13px;
      outline: none;

      font-size: 18px;
      font-weight: 300;
      line-height: 1.44;
      color: #959595;

      border: none;
      //border-bottom: 1px solid #cccccc;

      &::-webkit-input-placeholder {
        color: #959595;
        transition: all 0.25s ease;
      }
      &::-moz-placeholder {
        color: #959595;
        transition: all 0.25s ease;
      }
      &:-moz-placeholder {
        color: #959595;
        transition: all 0.25s ease;
      }

      &:focus::-webkit-input-placeholder {
        color: transparent;
      }
      &:focus::-moz-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }

    }

    textarea {
      min-height: 72px;
      resize: none;
      padding-top: 7px;
      background: transparent;
      overflow: hidden;
    }

    &_toggle {
      display: flex;
      justify-content: flex-start;
      margin-top: -12px;

      input[type='checkbox']:checked ~ .form__checkbox {
        border-color: @green;
        translate: 0.25s ease-in-out;

        &:before {
          transform: scale(1);
          translate: 0.25s ease-in-out;
        }
      }

      &:after {
        display: none;
      }

    }

    .form__field-element_checkbox {
      input[type="checkbox"] {
        width: 1px;
        height: 1px;
        visibility: hidden;
        opacity: 0;
        position: absolute;
        top: -99999px;
        left: 99999px;
      }
      input[type="checkbox"]:checked + label:before {
        transform: scale(1);
      }
    }

    .form__field-label {
      font-size: 12px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      text-align: left;
      color: #959595;

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .error_message {
      display: none;
      opacity: 0;
      font-size: 12px;
      margin-top: -16px;
      margin-left: 32px;
      color: #cc5b3c;
    }
    &.error_field {
      .error_message {
        display: block;
        opacity: 1;
        position: absolute;
      }

      .form__checkbox {
        border-color: red;
      }
    }

    &.focused .form__label,
    &.filled .form__label {
      opacity: 1;
      visibility: visible;
      transform: translateY(0%);
      transition: 0.25s ease-in-out;
    }

    &.focused:before,
    &.filled:before {
      transform: translateX(0%);
      transition: 0.5s ease;
    }

  }

  &__checkbox {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 17px;
    height: 17px;
    margin: 0 10px 0 0;
    border: solid 1px @grey;
    cursor: pointer;

    translate: 0.25s ease-in-out;

    &:before {
      content: '';

      width: 9px;
      height: 9px;
      background: @green;
      background-size: contain;

      transform: scale(0);
      translate: 0.25s ease-in-out;

    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0px;

    opacity: 0;
    visibility: hidden;
    transform: translateY(100%);

    font-size: 14px;
    font-weight: 300;
    line-height: 1.86;
    text-align: left;
    color: #b9b9b9;

    transition: 0.25s ease-in-out;

  }

  &__error {
    position: absolute;
    top: 0;
    right: 0;

    display: none;

    font-size: 14px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.86;
    letter-spacing: normal;
    text-align: right;
    color: #dd2429;
  }

  &__button {
    display: flex;
    justify-content: center;
    margin-top: 26px;
  }

  &__field-clear {
    position: relative;
    width: 11px;
    height: 11px;
    margin: 0 0 0 auto;

    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 1px;
      height: 100%;
      background: #dd2429;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.success {
  &__image {
    text-align: center;
    margin-bottom: 30px;
    svg {
      width: 100px;
      height: 100px;
      path {
        fill: @green;
      }
    }
  }

  &__text {
    font-size: 24px;
    font-weight: 500;
    color: @green;
    text-transform: uppercase;
    text-align: center;

    &_error {
      color: @grey;
    }
  }
}

.cooperation__form {
  max-width: 500px;
  margin: 24px auto 36px;
}

/*
contacts
*/

.contacts {
  position: relative;
  padding: 120px 0 96px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;

    display: block;
    width: 1px;
    height: 96px;
    background: @green;
  }
  &__map {
    max-width: 100%;
    width: 810px;
    height: 444px;
    margin: 48px auto 36px;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f0f0f0;
    display: block;
    height: 40%;
  }

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 36%;
    padding-left: 10px;
  }

  &__icon {
    position: absolute;
    //top: -1px;
    //left: -6px;
    //transform: translateX(-100%);
    //width: 26px;
    //height: 26px;
    top: 50%;
    left: 0;
    transform: translate(-100%, -50%);
    max-width: 25px;
    max-height: 35px;
    object-fit: contain;
  }

  &__text {
    font-size: 18px;
    font-weight: normal;
    line-height: 1.33;
    text-align: left;
    color: #323a45;
    transition: 0.25s ease;
  }

  a.contacts__text:hover {
    color: @green;
    transition: 0.25s ease;
  }
}

/*
modals
*/

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;

  visibility: hidden;
  opacity: 0;

  background-color: rgba(50, 58, 69, 0.7);

  &.visible {
    visibility: visible;
    opacity: 1;
  }
}

.modal-container {
  display: none;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  max-width: 1070px;
  //margin: 15vh auto 0;
  margin: 0 auto 0;
  padding: 90px 135px;
  background: @white;

  &__info {
    width: 60%;
    padding-left: 30px;
  }

  &__img {
    width: 40%;
    img {
      width: 100%;
      height: auto;
      min-height: 55%;
    }
  }

  &__info p,
  &__info li,
  &__info a {
    font-size: 16px;
    font-weight: 300;
    font-style: normal;
    line-height: 1.67;
    text-align: left;
    color: #71777f;
  }

  &__info ul {
    padding-left: 20px;
    margin-bottom: 1rem;
  }
  &__info li {
    list-style: disc;
  }

  &__info .subtitle {
    //font-size: 32px;
    margin-top: 0.5rem;
    margin-bottom: 1.25rem;
  }

  &__close {
    position: absolute;
    top: 25px;
    right: 37px;
    width: 25px;
    height: 25px;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 100%;
      background: #aacd21;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  &_form {
    //position: fixed;
    //top: 300px;
    .text-center {
      margin: 0 auto 1rem;
    }
  }

  &.visible {
    display: flex;
    flex-wrap: wrap;
    z-index: 9999;

    &.modal-container_form {
      position: fixed;
      top: 50%;
    }
  }

  &_review {
    .modal-container__info {
      width: 100%;
      padding-left: 0;
    }
  }

  &_bg {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
      height: 232px;
      background: url("/public/img/popup-bg.png") no-repeat center bottom;
    }
  }
}

/*
footer
*/

.fixed-button__btn {
  position: fixed;
  left: 50%;
  bottom: 15vh;
  transform: translate(-50%, 0);
  z-index: 5;

  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
  //width: 81px;
  //height: 81px;

  div {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 82px;
    height: 82px;
    border-radius: 50%;
    background-color: #81b03c;
    animation: shadow-pulse 1.5s infinite linear;
  }

  span {
    position: absolute;
    top: 22px;
    right: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    max-width: 0;
    overflow: hidden;
    height: 44px;
    padding: 0 0 0 25px;
    background: #81b03c;
    transform: translateX(-40px);
    font-size: 16px;
    font-weight: normal;
    text-align: left;
    color: @white;
    text-transform: uppercase;
    opacity: 0;
    transition: 0.4s ease-in-out;
  }

  &:hover span {
    max-width: 999px;
    opacity: 1;
    transition: 0.4s ease-in-out;
  }
  @-webkit-keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px @green;
    }
    30% {
      box-shadow: 0 0 0 16px rgba(170, 250, 33, 0.7);
    }
    100% {
      box-shadow: 0 0 0 38px rgba(170, 250, 33, 0.2);
    }
  }
  @keyframes shadow-pulse {
    0% {
      box-shadow: 0 0 0 0px @green;
    }
    30% {
      box-shadow: 0 0 0 16px rgba(231, 220, 197, 0.7);
    }
    100% {
      box-shadow: 0 0 0 38px rgba(253, 249, 252, 0.2);
    }
  }
}

.footer {
  &__top {
    padding: 60px 0;
    background: @dark-grey;
  }
  &__bottom {
    padding: 20px 0;
    background: #1f242c;
  }

  &__text,
  &__text a {
    font-size: 16px;
    font-weight: 300;
    line-height: 1.75;
    text-align: left;
    color: #ffffff;

    transition: 0.15s ease-in-out;

    &:last-child {
      text-align: right;
    }
  }

  &__text a:hover {
    color: #ff0048;
    transition: 0.15s ease-in-out;
  }

  .menu-item {
    color: @white;
    &:hover {
      color: @green;
    }
  }

  .telephone a {
    color: @white;
    &:hover {
      color: @green;
    }
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #ffffff inset !important;
  color: @dark-grey !important;
}

.animated {
  .initial-letter {
    padding: 0 20px;
    opacity: 0;
    transition: 0.5s ease-in-out;
  }

  .fade-bottom {
    transform: translateY(30px);
    opacity: 0;
    transition: 0.5s ease-in-out;
  }

  .fade {
    opacity: 0;
    transition: 0.5s ease-in-out;
  }

  .fade-left {
    transform: translateX(-30px);
    opacity: 0;
    transition: 0.5s ease-in-out;
  }

  .fade-right {
    transform: translateX(30px);
    opacity: 0;
    transition: 0.5s ease-in-out;
  }

  &.visible {
    .initial-letter {
      padding: 0px;
      opacity: 1;
      transition: 0.5s ease-in-out;
    }

    .fade,
    .fade-left,
    .fade-right,
    .fade-bottom {
      transform: translateY(0);
      opacity: 1;
      transition: 1.5s ease-in-out;
    }

  }
}

#about, #cases, #services, #solution, #industries, #team, #what-rain,
#ebrr-cooperation, #reviews, #cooperation, #contacts, .breadcrumbs,
.top-centered, .transcending-content, .timeline, .centered-content,
.list-link, .page404,
.team-slider {
  position: relative;
  z-index: 1;
  .poloska {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 0;

    width: 100%;
    height: 100%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-between;
    &_item {
      width: 1px;
      height: 100%;
      background: #e8e8e8;

    }
  }
}

#contacts {
  .poloska {
    z-index: -1;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media only screen and (min-width: 1441px) {
  .mainbanner__item {
    padding-top: 178px;
  }

  .mainbanner__navigation {
    top: 188px;
  }
}

@media only screen and (max-width: 1440px) {
  .mainbanner {
    height: 100vh;
    min-height: 0;
    max-height: none;
    br {
      display: none;
    }
  }

  .mainbanner__content, .mainbanner .slick-list, .mainbanner .slick-track {
    height: 100vh;
    min-height: 0;
    max-height: none;
  }

  .mainbanner__item-small {
    width: 60%;
    height: 66%;
  }

  .mainbanner__item .subtitle {
    font-size: 16px;
    margin-top: 7px;
  }

  .title {
    font-size: 24px;
    line-height: 24px;
  }

  .button {
    width: 228px;
    height: 44px;
  }

  .header {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .mainbanner__navigation .slick-dots li {
    height: 28px;
    &:before {
      top: 11.5px;
    }
  }
}

@media only screen and (max-width: 1199px) {

  .modal-container {
    width: 80%;
    padding: 50px 70px;

    max-height: 100vh;
    overflow: scroll;
  }

  .mainbanner {
    height: 100vh;
    min-height: 576px;
    overflow: hidden;

    &__content,
    .slick-list,
    .slick-track {
      height: 100vh;
      min-height: 576px;
    }

    &__navigation {
      width: 480px;
      top: 100px;
    }

    &__item {
      padding-top: 102px;
    }

    &__item-small {
      height: 50vh;
      min-height: 320px;
    }

    .title {
      font-size: 28px;
      line-height: 28px;
    }

    .subtitle {
      margin: 24px 0 36px;
    }

  }

  .initial-letter {
    max-height: 100px;
    font-size: 150px;
  }

  .telephone {
    justify-content: center;
    a {
      font-size: 16px;
      span {
        font-size: 18px;
      }
    }
  }

  .header {
    height: 72px;
    &__nav a {
      font-size: 18px;
    }

    &__logo {
      max-height: 100%;
      img {
        width: 147px;
        height: 51px;
        margin: 0 auto 0 0;
      }
    }
  }

  .left-image {
    padding: 84px 0 36px;
  }

  .success {
    padding: 72px 0;
  }

  .story {

    &__info {
      padding-right: 15px;
      padding-top: 48px;
    }

    &__list {
      right: 15px;
    }

    &__item-text {
      padding-left: 15px;
    }

    &__description {
      margin: 24px 0 48px;
    }

    &__img img {
      height: 100%;
    }
  }

  .hidden-list {
    &__title {
      font-size: 20px;
    }

    &__item {
      margin-bottom: 15px;
    }
  }

  .team {
    //&__main {
    //  width: 450px;
    //}
    &__additional {
      right: 100px;
    }
  }

  .member {
    &__left {
      width: 280px;
    }
    &__right {
      width: 410px;
    }
    &__photo {
      width: 410px;
    }

    &__link {
      bottom: 320px;
    }
  }

  .services {
    padding-top: 72px;

    &__list {
      margin-top: 120px;
    }
  }

  .team,
  .key-industries,
  .audience,
  .ebrr-cooperation,
  .reviews {
    padding: 72px 0 86px;
  }

  .review {
    &__list {
      margin-top: 36px;
    }
  }

  .cooperation {
    padding: 72px 0;
  }

  .contacts {
    padding-bottom: 60px;
    &__text {
      font-size: 16px;
    }
  }

  .footer {
    &__top {
      padding: 36px 0;
    }
    .menu-item {
      font-size: 18px;
    }
  }

}

@media only screen and (max-width: 991px) {

  .navigation {
    &__counter {
      margin: 0 25px;
    }
  }

  .mainbanner br {
    display: block;
  }

  .header {
    padding: 11px 0;
  }

  .title {
    font-size: 28px;
    line-height: 28px;

    &_left-right {
      .initial-letter {
        transform: translate(0, -50%)
      }
      &:before {
        width: 76px;
        top: 50%;
        right: 40px;
      }
    }

    &_left-line:before {
      width: 76px;
      margin-right: 25px;
    }

  }

  .button {
    width: 170px;
    height: 34px;
    font-size: 16px;
  }

  .subtitle {
    font-size: 18px;
  }

  .text {
    font-size: 16px;
    line-height: 1.5;
  }

  .text-light {
    &_18 {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .link {
    font-size: 16px;
  }

  .mainbanner {

    height: 576px;

    &__navigation {
      top: 114px;
      width: 360px;

      .slick-dots li {
        width: 30px;
        height: 30px;
        &:before {
          top: 12.5px;
          left: 12.5px;
          width: 4px;
          height: 4px;
        }
      }
    }

    &__content,
    .slick-list,
    .slick-track {
      height: 576px;
    }

    &__item {
      padding-top: 116px;
    }

    &__item-container {
      padding-bottom: 0px;
    }

    &__item-small {
      //bottom: unset;
      //top: 180px;
      min-height: unset;
      height: 250px;
    }

    .title {
      font-size: 24px;
      line-height: 24px;
    }

    .subtitle {
      margin: 4px 0 16px;
    }
  }

  .left-image {
    padding: 72px 0 60px;
    &__image {
      padding-left: 25px;
      padding-right: 65px;
    }

    &__text {
      padding-left: 55px;
    }

    &__subtitle {
      margin: 12px 0 36px;
      font-size: 16px;
    }
  }

  .success {
    padding: 36px 0 72px;

    .title {
      padding-left: 45px;
      padding-right: 125px;
      text-align: right;
    }

    &__top {
      padding-left: 42px;

      & > .row {
        align-items: center;
      }
    }

    &__stories {
      margin-top: 0px;
    }

  }

  .story {
    &__list {
      bottom: 30px;
    }
  }

  .services {

    .container {
      position: relative;
    }

    .services__list {
      margin-left: 75px;
      margin-right: 75px;
    }

    &__item {
      margin-bottom: 0;
    }

    &__navigation {
      position: absolute;
      top: 50%;
      left: 0px;
      right: 0px;

      height: 50px;

      .navigation__button_prev,
      .navigation__button_next {
        .circle-bg {
          stroke: #959595;
        }
        width: 50px;
        height: 50px;
        //border-color: #959595;
        &:after {
          border-left-color: #959595;
          border-right-color: #959595;
        }

        &:hover {
          .circle-bg {
            border-color: @green;
          }
          &:after {
            border-left-color: @green;
            border-right-color: @green;
          }
        }
      }

      .navigation__button_next {
        &:after {
          left: 25px;
        }
      }

    }
    &__navigation {
      .navigation__button {
        &_prev {
          left: 14px;
        }

        &_next {
          right: 14px;
        }
      }
    }

    &__title {
      font-size: 20px;
      line-height: 1.5;
    }

  }

  .hidden-list {
    margin-left: -35px;
    &__title {
      font-size: 18px;
      line-height: normal;

      .counter {
        top: 3px;
      }
    }

    &__text {
      margin-top: 5px;
      font-size: 14px;
    }
  }

  .key-industries {
    padding: 72px 0 60px;
    &__container {
      padding-top: 36px;
    }

    &__navigation {
      padding: 0;
    }
  }

  .team {
    padding: 72px 0;
    &__main {
      max-width: 83.333333%;
      margin: 0 auto;
    }

    &__subtitle {
      margin: 24px 0 0;
    }

    &__additional {
      top: 450px;
      right: 65px;
      width: 286px;
      height: 350px;

      &-img {
        height: 350px !important;
      }
    }

    &__navigation {
      padding: 0;
    }
  }

  .member {
    &__wrapper {
      flex-direction: column;

    }

    &__left {
      position: relative;

      align-items: center;
      width: 100%;
      padding: 0 52px 0 100px;
    }

    &__num {
      position: absolute;
      top: 0%;
      left: 52px;

      transform: translateY(0%);

      height: 100px;
      margin: 0;

      font-size: 150px;
      line-height: 100px;
      text-align: left;
      color: #f0f0f0;

      &:before {
        top: 50px;
        left: 50%;

        width: 76px;
        height: 1px;
      }
    }

    &__link {
      position: relative;
      bottom: unset;
    }

    &__name {
      font-size: 20px;
    }

    &__position {
      margin: 12px 0 36px;
      font-size: 16px;
    }

    &__right {
      width: 100%;
      height: 100%;
      margin: 24px auto 0;
    }

    &__photo {
      max-width: 400px;
      height: 500px;
      margin: 0 auto 38px;
    }

    &__blockquote {
      padding: 0 52px 0 125px;
      font-size: 16px;
      line-height: 1.63;
      transform: none;
      &:before {
        top: -11px;
        left: 62px;
        width: 52px;
        height: 45px;;
      }
    }
  }

  .audience {
    &__name {
      margin: 24px 0 12px;
      font-size: 18px;
    }

    &__item {
      margin-top: 36px;
    }
    &__item:nth-child(odd) .audience__text,
    &__item:nth-child(even) .audience__text {
      padding: 0;
    }
  }

  .ebrr-cooperation {
    padding: 72px 0;

    &__subtitle {
      font-size: 16px;
    }

  }

  .grey-card {
    margin-top: 36px;
    padding: 36px 60px 48px;
  }

  .reviews {
    padding: 72px 0;

    &__list {
      margin-top: 36px;
    }

    &__navigation {
      padding: 0;
    }
  }

  .review {
    flex-direction: column;
    &__image,
    &__info {
      width: 100%;
    }

    &__info {
      padding-left: 0;
    }

    &__name {
      font-size: 18px;
      line-height: 1.67;
    }

    &__industry {
      margin: 12px 0;
      padding-right: 200px;
      font-size: 16px;
    }

    &__position {
      margin: 0 0 24px;
      font-size: 16px;
    }

    &__link {
      margin-top: 12px;
    }
  }

  .cooperation {
    padding: 72px 0 36px;

    &__subtitle {
      margin: 24px 0 12px;
      font-size: 16px;
    }
  }

  .form {
    &__field {
      max-width: 415px;
      margin: 0 auto 24px;

      input,
      textarea {
        font-size: 16px;
      }
    }

    &__button {
      button {
        width: 170px;
        height: 36px;
        margin-top: 0px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.63;
      }
    }
  }

  .contacts {
    //background: #f0f0f0;

    &:after {
      height: 72px;
    }

    &_page {
      padding-top: 100px;
    }

    &__map {
      margin-top: 36px;
      padding: 0 27px;
      height: 408px;
    }

    &__bottom {
      padding: 0 27px 0 42px;
    }

    &__icon {
      width: 20px;
      max-height: 25px;
    }
  }

  .footer {
    &__top {
      display: none;
    }
  }

  .modal-container {
    &__img,
    &__info {
      width: 100%;
      padding-left: 0;
    }

    &__img img {
      display: block;
      margin: 0 auto 25px;
      width: auto;
      max-width: 100%;
      max-height: 250px;
    }

    &__info .subtitle {
      //font-size: 28px;
    }
  }

}

@media only screen and (max-width: 767px) {
  .poloska {
    display: none !important;
  }

  .animated {
    .initial-letter {
      padding: 0 20px;
      opacity: 1;
      transition: 0s ease-in-out;
    }

    .fade-bottom {
      transform: translateY(0px);
      opacity: 1;
      transition: 0s ease-in-out;
    }

    .fade {
      opacity: 1;
      transition: 0s ease-in-out;
    }

    .fade-left {
      transform: translateX(0px);
      opacity: 1;
      transition: 0s ease-in-out;
    }

    .fade-right {
      transform: translateX(0px);
      opacity: 1;
      transition: 0s ease-in-out;
    }

  }

  .word {
    transform: translateY(0%);
    opacity: 1;
  }

  .navigation {
    &__counter {
      font-size: 16px;

    }
    &__counter-current {
      font-size: 21px;
    }
    .navigation__button_prev,
    .navigation__button_next,
    &__button {
      width: 38px;
      min-width: 38px;
      height: 38px;
    }

    .navigation__button {
      &_prev:after,
      &_next:after {
        border-width: 5px;
      }
      &_prev {
        transform: translateX(-15px);
        &:after {
          top: 50%;
          left: 50%;
          transform: translate(-60%, -50%)
        }
      }
      &_next {
        transform: translateX(15px);
        &:after {

          top: 50%;
          left: 50%;
          transform: translate(-45%, -50%)
        }
      }
    }

  }

  .fixed-button__btn {
    //width: 60px;
    //height: 60px;
    div {
      right: 15px;
      width: 60px;
      height: 60px;
    }
  }

  .modal-container__info p, .modal-container__info li, .modal-container__info a {
    font-size: 12px;
    line-height: normal;
  }

  .modal-container {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    padding: 40px 30px;
    overflow-y: scroll;
    &__close {
      top: 15px;
      right: 15px;
      width: 20px;
      height: 20px;

      &:before,
      &:after {
        height: 20px;
      }
    }

    &__info .title {
      //font-size: 20px;
    }

  }

  .initial-letter {
    height: 82px;
    font-size: 72px;
  }

  .title {
    font-size: 20px;
    line-height: 20px;
    &_left-line {
      &:before {
        top: 18px;
        right: unset;
        left: 0;
        transform: translateX(-50%);

        width: 35px;
        margin: 0;
      }
      .initial-letter {
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &_left-right {
      &:before {
        right: unset;
        left: 100%;
        width: 35px;
      }
      .initial-letter {
        right: unset;
        top: 50%;
        left: 100%;
      }
    }

    &_center {
      .initial-letter {
        height: 52px;
        font-size: 72px;
      }
      &:before {
        height: 35px;
      }
    }
  }

  .subtitle {
    font-size: 16px;
  }

  .text {
    p + p {
      margin-top: 10px;
    }
    p {
      margin-bottom: 0;
    }
  }

  .link {
    font-size: 14px;
  }

  .header {
    height: 60px;
    padding: 5px 0;

    &__logo {
      height: 45px;
    }

    &__tel {
      display: none;
    }
  }

  .mainbanner {

    height: 400px;
    min-height: 400px;

    &__content,
    .slick-list,
    .slick-track {
      height: 400px;
    }

    .subtitle {
      opacity: 1;
    }

    .button {
      opacity: 1;
    }

    &__item {
      padding: 102px 0 72px;
    }
    &__item-small {
      display: none !important;

    }

    &__navigation {
      width: 270px;
    }
    .slick-current {

      .subtitle {
        opacity: 1;
        transition: 0s ease-in-out;
        transition-delay: 0;
      }

    }

  }

  .left-image {
    padding: 48px 0 24px;

    &_reverse {
      .left-image__text .title {
        padding-left: 0px;
      }

      .left-image__image img {
        width: 100%;
        max-width: 100%;
      }

    }

    &__image {
      padding-left: 15px;
      padding-right: 15px;
    }

    &__subtitle {
      margin-bottom: 29px;
    }

    &__text {
      padding-top: 34px;
      padding-left: 15px;

      .title {
        padding-left: 65px;
        margin-bottom: 24px;
      }

    }
  }

  .hidden-container {
    &__text {
      max-height: 178px;
      font-size: 14px;
    }
  }

  .success {
    padding: 24px 0 96px;

    .title {
      padding: 15px 0;
    }

    &__navigation {
      position: absolute;
      bottom: 48px;
      left: 0;
      right: 0;
      height: auto;
      width: 200px;
      margin: 0 auto;

      .navigation__button_prev,
      .navigation__button_next,
      .navigation__button {
        width: 48px;
        min-width: 48px;
        height: 48px;
      }
    }

    &__stories {
      margin-top: 12px;
    }

  }

  .story {
    &__info {
      padding-top: 0px;
      padding-bottom: 340px;
    }
    &__title {
      font-size: 16px;
    }

    &__description {
      margin: 12px 0 24px;
      font-size: 14px;
    }

    &__item {
      justify-content: flex-end;
    }

    &__list {
      bottom: 0;
      //position: static;
    }

    &__item-img {
      width: 87px;
      min-width: 87px;
      height: 87px;
    }

    &__item-text {
      font-size: 14px;
      text-align: right;
    }

    &__img {
      display: none;
      //padding-left: 0;
      //padding-right: 0;
      //margin-top: 24px;
      //img {
      //  width: 100%;
      //  max-width: 100%;
      //  height: 300px;
      //}
    }
  }

  .services {
    padding: 36px 0 12px;

    &__list {
      margin-top: 72px;
    }

    .services__list {
      margin-left: 30px;
      margin-right: 40px;
    }

    &__title {
      padding-left: 20px;
      font-size: 14px;
    }

    &__counter {
      position: static;
      transform: none;
      display: block;
      font-size: 16px;
    }

    &__text {
      padding-left: 20px;
      font-size: 14px;
    }
  }

  .hidden-list {
    margin-left: 0;
    &__title {
      padding-left: 25px;
      margin-bottom: 10px;
      font-size: 14px;
      .counter {
        top: 0;
        font-size: 14px;
      }
    }

    &__text {
      padding-left: 0;
      font-size: 12px;
    }
  }

  .key-industries {
    padding: 48px 0;
    &__container {
      padding-top: 24px;
    }

    &__name {
      font-size: 16px;
    }
  }

  .team {
    padding: 48px 0;
    &__subtitle {
      margin: 24px 0;
    }

    &__additional {
      top: 330px;
      right: 45px;
      width: 200px;
      height: 250px;
    }

  }

  .member {

    &__wrapper {
      padding-top: 24px;
    }

    &__left {
      align-items: flex-start;
      padding-left: 75px;
    }

    &__photo {
      width: 345px;
      height: 375px;
      margin: 0;
    }
    &__name {
      font-size: 16px;
    }

    &__num {
      top: 0;
      left: 0;
      transform: none;
      height: 53px;
      line-height: 40px;
      font-size: 72px;
      &:before {
        top: 22px;
        width: 35px;
      }
    }
    &__position {
      margin: 12px 0 12px;
      font-size: 14px;
    }

    &__blockquote {
      padding: 24px 80px 0 35px;

      font-size: 16px;
      font-weight: 300;
      line-height: 1.29;
      &:before {
        top: 24px;
        left: 0;
        width: 24px;
        height: 24px;
      }
    }
  }

  .audience {
    padding: 48px 0;

    &__wrapper {
      position: relative;
    }

    &__navigation {
      display: none;
      position: absolute;
      top: 100px;
      width: 100%;

      .navigation__button_prev,
      .navigation__button_next {
        border-color: #959595;
        &:after {
          border-left-color: #959595;
          border-right-color: #959595;
        }
      }

      .navigation__button_prev {
        transform: translateX(-35px);
      }

      .navigation__button_next {
        transform: translateX(35px);
      }
      .circle-bg {
        stroke: #959595;
      }

    }

    &__item,
    &__item:nth-child(odd) {
      margin: 0;
      text-align: center;
    }

    &__container {
      margin: 24px 30px 0;
    }

    &__name {
      margin: 24px 0 12px;
      font-size: 16px;
    }

    &__text {
      font-size: 14px;
    }
  }

  .ebrr-cooperation {
    padding: 48px 0;

    &__subtitle {
      margin: 24px 0;
      font-size: 14px;
    }
  }

  .grey-card {
    margin-top: 24px;
    padding: 23px;

    .text-light_18 {
      font-size: 14px;
      p {
        margin-bottom: 5px;
      }
    }
  }

  .reviews {
    padding: 48px 0;

    &__list {
      margin-top: 24px;
    }

    &__navigation {
      top: 185px;
      height: 35px;
    }
  }

  .review {
    &__info {
      padding: 24px 0 0 0px;
    }

    &__name {
      font-size: 16px;
    }
    &__industry {
      padding-right: 100px;
      font-size: 14px;

      &:before {
        width: 34px;
        height: 29px;
      }
    }

    &__position {
      margin: 0 0 12px;
      font-size: 14px;
    }

    &__image {
      height: 350px;
    }

    .text-light_18 {
      font-size: 14px;
    }

  }

  .cooperation {
    padding: 48px 0;

    &__subtitle {
      margin: 16px 0 24px;
      font-size: 14px;
    }
  }

  .form {
    &__field {
      width: 70%;
      margin-bottom: 12px;
    }

    &__button {
      margin-top: 0;
      .button {
        width: 214px;
        height: 36px;
        margin-top: 12px;
      }
    }
  }

  .contacts {
    margin-top: 160px;
    padding: 0px 0 24px;
    background: #f0f0f0;

    &_section {
      .title {
        display: none;
      }

    }

    &:after {
      height: 72px;
    }

    &_section {
      .title {
        display: none;
      }
      .contacts__map {
        height: 325px;
        padding: 0;
        margin-bottom: -130px;
        transform: translateY(-160px);
      }
    }

    &_page {

      margin-top: 190px;
      .contacts__map {
        height: 325px;
        padding: 0;
      }

      .title {
        margin-bottom: -100px;
        transform: translateY(-100px);
      }

      &:after {
        top: -160px;
        height: 45px;
      }

    }

    &__map {
      //height: 325px;
      //padding: 0;
      //margin-bottom: -130px;
      //transform: translateY(-160px);
    }

    &__bottom {
      padding: 10px 40px 25px;
      flex-wrap: wrap;
    }

    &__item {
      width: 100%;
      max-width: 100%;
      margin-bottom: 24px;
      padding-left: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .footer {
    &__bottom {
      padding: 12px 0;
    }
  }

}

@media only screen and (max-width: 575px) {

  .modal-container {
    //&__info {
    //  max-height: 300px;
    //  overflow-y: scroll;
    //}
  }

  .navigation {
    .navigation__button {

      &_prev {
        transform: translateX(-0px);
      }
      &_next {
        transform: translateX(0px);
      }
    }
  }

  .title_left-right .initial-letter {
    transform: translate(0%, -50%);
  }

  .mobile-menu {
    .navigation {
      display: block;
      &__list {
        width: 100%;
        margin-top: 15px;
      }
    }
  }

  .slick-slide img {
    //display: none !important;
  }

  .button {
    width: 107px;
    height: 23px;
    font-size: 12px;
  }

  .title {
    font-size: 18px;
    line-height: 18px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 16px;
  }

  .header {
    &__logo {
      img {
        width: 128px;
        height: 45px;
      }
    }
  }

  .mainbanner {
    height: 320px;
    min-height: 320px;

    &__item-full {
      display: none !important;
    }

    &__content,
    .slick-list,
    .slick-track {
      height: 320px;
    }

    .title {
      font-size: 18px;
      line-height: 21px;
    }

    .subtitle {
      margin: 12px 0;
      font-size: 12px;
    }

    &__navigation {
      top: unset;
      left: 0;
      right: 0;
      bottom: 0;

      width: 100%;

      .slick-dots {
        display: flex;
        justify-content: center;
        margin: 0 auto 12px;
      }
    }
  }

  .left-image {

    &_reverse {
      .left-image__text .title .initial-letter {
        left: unset;
        right: 0;
        transform: translate(0%, -50%)
      }

      .title_left-right:before {
        right: 0;
        left: unset;
        width: 35px;
      }
    }

    &__image {
      img {
        display: block;
        //width: 210px;
        margin: 0 auto;
      }
    }

    &__subtitle {
      font-size: 12px;
    }

    &__text {
      .title {
        padding-left: 48px;
        .initial-letter {
          left: -5px;
        }
      }
    }

  }

  .hidden-list {
    &__title {
      padding-left: 18px;
      margin-bottom: 0px;
      font-size: 14px;
    }

    &__arrow {
      //position: absolute;
      //left: 100%;
      width: 11px;
      height: 6px;
    }
  }

  .key-industries {
    .slick-track {
      display: flex;
      align-items: center;
    }

    .key-industries__image {
      max-width: 45px;
      max-height: 40px;
      margin: 0 auto 20px;
    }

    &__name {
      font-size: 14px;
    }
  }

  .success {
    padding: 24px 0 72px;

    &__navigation {
      bottom: 36px;
    }

    &__top {
      padding-bottom: 35px;
    }

    &__title {
      text-align: right;
      padding-right: 85px;
    }

    .navigation .navigation__button_prev,
    .navigation .navigation__button_next,
    .navigation__button {
      width: 48px;
      height: 48px;
      min-width: 48px;

      &:after {
        left: 50%;
        transform: translate(-55%, -50%);
      }
    }
  }

  .story {

    &__description {
      font-size: 14px;
      line-height: 1.29;
    }

    &__item-text {
      padding: 0 25px 0 0;
      font-size: 14px;
    }

    &__img {
      height: 0px;
      img {
        display: none !important;
      }
    }

  }

  .services {
    &__navigation {
      left: 0px;
      right: 0px;
    }
  }

  .team {
    padding: 48px 0 24px;

    &__container {
      padding: 0 40px;
    }

    &__navigation {
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 30px;
      //top: 200px;
      //left: 10px;
      //right: 10px;
      //width: auto;
    }

    &__subtitle {
      margin: 12px 0;
    }

    &__main {
      max-width: 100%;
      width: 100%;
    }

    &__additional {
      //top: 220px;
      //right: 45px;
      //width: 110px;
      //height: 140px;
      display: none !important;
    }

  }

  .member {

    &__wrapper {
      padding-top: 12px;
    }

    &__left {
      //padding: 0 0 0 50px;
      width: auto;
      margin: 0 auto;
      padding: 0 0 0 50px;
    }

    &__right {
      margin-top: 12px;
    }

    &__name {
      font-size: 15px;
      text-align: left;
    }

    &__position {
      font-size: 12px;
      text-align: left;
    }

    &__photo {
      width: 180px;
      height: 195px;
      width: 100%;
      height: auto;
      max-width: 360px;
      margin: 0 auto;
    }

    &__num {
      font-size: 60px;
      &:before {
        top: 12px;
        left: 10px;
      }
    }

    &__position {
      margin: 5px 0 20px;
    }

    &__blockquote {
      padding: 12px 0px 0 25px;
      font-size: 14px;
      line-height: 18px;

      &:before {
        top: 12px;
        width: 20px;
        height: 20px;
      }
    }

  }

  .audience {
    padding: 48px 0 24px;

    &__container {
      margin: 15px 20px 0;
    }
    &__text {
      font-size: 14px;
      line-height: 18px;
    }

    &__item {
      img {
        width: 212px;
        height: 120px;
        margin: 0 auto;
      }
    }

    &__navigation {
      top: 45px;
      height: 31px;
    }
  }

  .ebrr-cooperation .grey-card__image {
    width: 120px;
    height: 80px;
    margin-bottom: 12px;
  }

  .ebrr-cooperation__subtitle {
    margin: 12px 0 24px;
  }

  .grey-card {
    padding: 18px 24px 24px;
    .text-light_18 {
      font-size: 12px;
      line-height: normal;
    }
  }

  .reviews {
    &__list {
      margin: 18px 40px;
      width: 100%;
      max-width: calc(~"100% - 80px");
    }
    &__navigation {
      top: 95px;
      left: 10px;
      right: 10px;
      height: 40px;
    }
  }

  .review {
    &__image {
      height: 190px;
      max-width: 100%;
      object-fit: cover;
      //display: none;
      img {
        object-fit: cover;
      }
    }
    &__industry {
      margin: 6px 0;
      padding-right: 0;
      font-size: 14px;
      line-height: 18px;
      &:before {
        bottom: 100%;
        top: unset;
        right: 5px;
      }
    }

    .text-light_18 {
      line-height: normal;
    }
  }

  .form {
    &__field {
      width: 215px;
      height: 36px;
      margin-bottom: 6px;
      input,
      textarea {
        min-height: 24px;
        font-size: 14px;
      }

    }

    &__label {
      left: 0px;
      font-size: 10px;
    }

    &__button {
      button {
        width: 215px;
        height: 36px;
        font-size: 14px;
      }
    }
  }

  .contacts {
    margin-top: 90px;
    padding-bottom: 0;

    &_section {
      .title {
        display: none;
      }
      .contacts__map {
        height: 180px;
        min-width: calc(~ "100% + 20px");
        margin: 0 -10px -70px;
        transform: translateY(-90px);
      }
    }

    &_page {

      margin-top: 120px;

      .contacts__map {
        height: 180px;
        margin-top: 10px;
        margin-bottom: 15px;
      }

      .title {
        margin: 0px -10px -45px;
        transform: translateY(-60px);
      }
      .contacts__bottom {
        padding-bottom: 30px;
      }

      &:after {

        top: -110px;
        height: 35px;
      }

    }

    &__bottom {

      background: #f0f0f0;
    }

    &__text {
      font-size: 14px;
    }
  }

  .footer__text, .footer__text a {
    font-size: 12px;
  }

}

@import "blocks";
@import "services";
@import "team";