@import "variables";

.no-scroll {
  overflow: hidden;
}

.breadcrumbs {
  padding: 120px 0 0;
  &__list {
  }

  &__item {
    position: relative;
    margin-right: 12px;

    &:before {
      content: '/';
      position: absolute;
      left: 100%;
      top: 50%;
      transform: translate(50%, -50%);
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #71777f;
    }
    a, span {
      font-size: 14px;
      font-weight: 300;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      text-align: left;
      color: #71777f;
    }

    &:last-child {
      a, span {
        color: #cfcfcf;
      }
      &:before {
        display: none;
      }
    }
  }
}

.custom-list {
  padding-left: 25px;
  &__item {
    position: relative;
    margin-bottom: 30px;

    font-size: 18px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #71777f;
    &:before {
      content: '';
      position: absolute;
      top: 6px;
      left: -25px;

      width: 12px;
      height: 12px;
      background: #acd021;
      border-radius: 50%;
    }
  }
}

.title-text {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #323a45;
}

.page-wrap {
  width: 100%;
  //min-height: 95vh;
  overflow: hidden;
}

.page404 {
  position: relative;
  position: relative;
  padding: 115px 0 340px;
  min-height: 95vh;

  .poloska {
    z-index: -1;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -170px;
    left: 50%;
    z-index: 1;

    display: block;
    width: 1320px;
    height: 626px;
    transform: translate(-50%, 0px);
    background: url("/public/img/404.png") no-repeat center;
  }

  &__number {
    font-size: 200px;
    font-weight: normal;
    text-align: center;
    color: #323a45;
  }

  &__text {
    font-size: 24px;
    font-weight: normal;
    line-height: normal;
    text-align: center;
    color: #323a45;
    text-transform: uppercase;
  }

  &__link {
    margin-top: 75px;
    text-align: center;
  }

  .container:last-child {
    position: relative;
    z-index: 2;
  }
}

.list-link {
  padding: 10px 0 70px;

  min-height: calc(~"100vh - 390px");

  &_black {

    .list-link__link,
    .list-link__title .list-link__counter {
      color: #ffffff;
    }

    .list-link__title {
      a:before {
        display: none;
      }

      svg {
        width: 26px;
      }

      .st0 {
        fill: #FFFFFF;
        transition: 0.25s ease-in-out
      }
    }

    .list-link__item:hover {
      .list-link__link,
      .list-link__title .list-link__counter {
        color: @black;
      }

      .st0 {
        fill: #000000;
        transition: 0.25s ease-in-out;
      }
    }
  }

  &__column:last-child {
    padding-left: 0;
  }

  &__item {
    margin: 30px 0;
    overflow: hidden;

    &.visible {
      .list-link__title {
        transform: translateX(0%);
        transition: 1.5s ease-in-out;
      }
    }
  }

  &__title {
    position: relative;
    padding-right: 100px;

    padding-left: 32px;

    transform: translateX(-100%);
    transition: 0.5s ease-in-out;

    .list-link__counter,
    a {
      font-size: 22px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.55;
      letter-spacing: normal;
      text-align: left;
      color: #71777f;

      transition: 0.25s ease-in-out;

    }

    a {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 6px;
        left: 100%;
        transform: translate(0%, -50%);

        display: block;
        width: 38px;
        margin-left: 8px;
        height: 8px;
        background: url("/public/img/arrow.svg") no-repeat center center;
        background-size: 100%;
        transition: 0.25s ease-in-out;
      }

    }

    .list-link__counter {
      font-size: 18px;
    }

    &:hover {
      .list-link__counter,
      a {
        color: #7baf33;
        transition: 0.25s ease-in-out;

        &:before {
          transform: translate(25%, -50%);
          transition: 0.25s ease-in-out;
        }
      }
    }
  }

  &__counter {
    position: absolute;
    transform: rotate(-90deg);
    left: 0;
  }
}

.lang-switcher {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 100%;

    width: 8px;
    height: 4px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4'%3E%3Cpath fill='%23323A45' fill-rule='evenodd' d='M.442 0h7.107c.33 0 .576.194.383.385-.155.155-3.279 3.226-3.546 3.488-.176.172-.595.17-.77 0A869.569 869.569 0 0 1 .061.377C-.099.215.078 0 .442 0z'/%3E%3C/svg%3E%0A") no-repeat center center;

    transform: translate(8px, -50%);

  }

  &__current {
    cursor: pointer;
  }
  &__list {
    position: absolute;
    top: 100%;
    left: 0;

    width: auto;

    opacity: 0;
    visibility: hidden;

    trnasform: translateY(25px);

    transition: 0.25s ease-in-out;

    a {
      color: @grey;
      transition: 0.25s ease-in-out;
      &:hover {
        color: @green;
        transition: 0.25s ease-in-out;
      }
    }
  }

  &:hover {
    .lang-switcher__list {
      opacity: 1;
      visibility: visible;
      transition: 0.25s ease-in-out;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .page404 {
    padding: 100px 0 240px;
    &__number {
      font-size: 140px;
    }

    &__link {
      margin-top: 50px;
    }

    &:before {
      bottom: -150px;
      width: 990px;
      height: 500px;
      background-size: 100%;
    }
  }
}

@media only screen and (max-width: 991px) {

  .breadcrumbs {
    padding-top: 95px;
  }

  .title-text {
    font-size: 16px;
  }

  .list-link {
    min-height: calc(~"100vh - 180px");
    &__title {
      padding-right: 45px;
      padding-left: 20px;
    }

    &__title {

      a {
        font-size: 18px;
        &:before {
          bottom: 3px;
        }
      }

      .list-link__counter {
        font-size: 14px;
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  .breadcrumbs {
    padding-top: 65px;
    &__item {
      line-height: normal;
      a,
      span {
        font-size: 10px;
        line-height: normal;
      }
    }
  }

  .page404 {

    display: flex;
    align-items: center;
    min-height: 95vh;
    padding: 70px 0 250px;
    &:before {
      bottom: -100px;
      width: 575px;
      height: 400px;
      background-size: 100%;
    }

    &__number {
      font-size: 100px;
    }

    &__text {
      font-size: 20px;
    }

    &__link {
      margin-top: 30px;
    }
  }

  .list-link {

    padding: 20px 0 40px;

    &__column:last-child {
      padding-left: 15px;
    }

    &__item {
      margin: 15px 0;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &__title {
      padding-right: 15px;

      transform: translateX(0%);

      a {
        font-size: 16px;
        &:before {
          width: 25px;
          bottom: 1px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {

  .page404 {
    align-items: flex-start;
    padding: 150px 0 250px;
    min-height: auto;
    &:before {
      bottom: -50px;
      width: 480px;
      background-size: 100%;
      background-position-y: bottom;
    }

    &__number {
      font-size: 100px;
    }

    &__text {
      font-size: 20px;
    }

    &__link {
      margin-top: 30px;
    }
  }

}