@import "variables";

.team-basic {
  .slick-track {
    display: flex !important;
  }
  .slick-slide {
    float: none;
  }
}

.team-slider {
  padding-top: 60px;
  padding-bottom: 120px;

  &__top {

    .container {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;

        transform: translate(-50%, 50%);

        width: 539px;
        height: 143px;

        background: url("/public/img/team-page/team-rain.png") no-repeat center center;
      }

    }

    .navigation__button {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &_prev {
        left: 30px;
      }

      &_next {
        right: 30px;
      }

      .circle-bg {
        stroke: #d5d5d5;
      }

    }

  }

  &__bottom-container {
    position: relative;

  }
}

.team-navigation {
  margin: 0px 90px 130px;


  &__img {
    position: relative;

    width: 262px;
    height: 272px;
    margin: 0 40px;

    cursor: pointer;

    transition: 0.25s ease-in-out;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: #000;
      opacity: 0.5;
      transition: 0.5s ease-in-out;
    }

    //&.slick-active {
    //  opacity: 1;
    //  transition: 0.25s ease-in-out;
    //}

    &:hover:before {
      opacity: 0;
      transition: 0.5s ease-in-out;
    }
  }


}

.member-basic {
  &__image {
    width: 555px;
    height: 576px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__info {
    padding: 30px 0;
  }
  &__name {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #323a45;
  }

  &__position {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #323a45;
  }

  &__link {
    position: relative;
    display: inline-block;
    margin-top: 25px;
    padding-right: 35px;
    &:before {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0%, -50%);
      width: 30px;
      height: 100%;
      background: url("/public/img/arrow.svg") no-repeat center center;
      background-size: 100%;
      transition: 0.25s ease-in-out;
    }

    &:hover:before {
      transform: translate(25%, -50%);
      transition: 0.25s ease-in-out;
    }
  }

  &__blockquote {
    transform: unset;
    margin: 45px 10px 0 90px;
  }
}

@media only screen and (max-width: 991px) {

  .team-slider {
    padding-top: 40px;
    padding-bottom: 70px;
    &__top {
      .container {
        &:before {
          width: 50%;
          height: 100px;
          background-size: contain;
        }
      }
      .navigation__button_prev {
        left: 17px;

      }
      .navigation__button_next {
        right: 17px;

      }
    }
  }

  .team-navigation {
    margin: 0px 45px 80px;

    &__img {
      margin: 0 15px;
    }

  }

  .member-basic {
    &__image {
      width: 100%;
      height: 500px;
    }
    &__name {
      font-size: 20px;
    }

    &__position {
      font-size: 18px;
    }
    &__link {
      margin-top: 10px;
    }

    &__blockquote {
      margin: 25px 10px 0 20px;
      padding-right: 0;
    }
  }

}

@media only screen and (max-width: 767px) {
  .member-basic {
    &__image {
      width: 320px;
      height: 400px;
      margin: 0 auto;
    }

    &__blockquote {
      margin: 0px;
    }
  }

  .team-slider {
    &__top {
      .navigation__button_prev {
        &:after {
          left: 18px;
        }
      }
      .navigation__button_next {
        &:after {
          left: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {

  .team-slider {
    padding: 20px 0;
  }

  .team-navigation {
    margin-bottom: 40px;
    &__img:before {
      display: none;
    }
  }

  .member-basic {

    &__name {
      font-size: 18px;
    }

    &__position {
      font-size: 16px;
    }

    &__image {
      display: none;
    }

    &__info {
      padding-top: 0;
    }

  }
}