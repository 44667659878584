@import "../fonts/stylesheet.css";

/* fonts */
@roboto: 'Roboto';

/*color
*/

@white: #ffffff;
@black: #000000;

@light-grey: #f0f0f0;
@grey: #878a8f;
@grey-text: #71777f;

@dark-grey: #323a45;
@dark-grey-opacity: rgba(50,58,69,0.5);


@green: #aacd21;
@green-light: #7baf33;
@green-hover: #4d8108;
