@import "../fonts/stylesheet.css";
/* fonts */
/*color
*/
/*
common styles
*/
* {
  outline: none;
  font-family: 'Roboto';
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
h1,
h2,
h3,
ul,
p:last-child {
  margin-bottom: 0;
}
li {
  list-style: none;
}
.textWrapper {
  font-weight: 800;
  font-size: 6vw;
  color: #fff;
}
.wordContainer {
  display: inline-block;
  overflow: hidden;
}
/* Then, set the transform */
.word {
  transform: translateY(115%);
  opacity: 0;
}
.menu-item {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  color: #323a45;
  transition: 0.25s ease-in-out;
}
.menu-item:hover {
  color: #aacd21;
  transition: 0.25s ease-in-out;
}
.ml12 {
  opacity: 0;
  position: relative;
  font-weight: 900;
  font-size: 4em;
}
.ml12 .text-wrapper {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}
.ml12 .letter {
  display: inline-block;
  line-height: 1em;
  transform-origin: 0 0;
  opacity: 0;
}
.title {
  position: relative;
  font-size: 36px;
  line-height: 36px;
  font-weight: 500;
  color: #323a45;
  text-transform: uppercase;
}
.title__word {
  position: relative;
  display: inline-block;
  padding-top: 0.2em;
  padding-right: 0.05em;
  padding-bottom: 0.1em;
  overflow: hidden;
}
.title__letter {
  display: inline-block;
  line-height: 1em;
  transform-origin: 0 0;
}
.title_left-line .initial-letter {
  position: absolute;
  z-index: -1;
  left: 20px;
  top: 0;
  transform: translate(-95%, -50%);
}
.title_left-line:before {
  content: '';
  position: absolute;
  top: 21px;
  right: 100%;
  z-index: 2;
  display: block;
  width: 98px;
  height: 1px;
  margin-right: 26px;
  background: #aacd21;
}
.title_left-right {
  display: inline-block;
  padding-right: 110px;
}
.title_left-right .initial-letter {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(60%, -50%);
}
.title_left-right:before {
  content: '';
  position: absolute;
  top: 21px;
  right: 0;
  z-index: 2;
  display: block;
  width: 98px;
  height: 1px;
  margin-left: 11px;
  background: #aacd21;
}
.title_center {
  text-align: center;
  z-index: 3;
}
.title_center:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 3;
  display: block;
  width: 1px;
  height: 98px;
  background-color: #aacd21;
  transform: translateX(-50%);
}
.title_center .initial-letter {
  position: absolute;
  top: 0%;
  left: 50%;
  z-index: -1;
  height: 140px;
  color: #ffffff;
  transform: translate(-50%, -50%);
}
.hide-wrapper {
  display: inline-block;
  overflow: hidden;
}
.hide-wrapper span {
  display: block;
  transform: translateY(100%);
  opacity: 0;
  transition: 1s ease;
}
.visible .hide-wrapper span {
  opacity: 1;
  transform: translateY(0%);
  transiton: 2s ease-in-out;
}
.service-title {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
  color: #323a45;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 245px;
  height: 48px;
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  text-decoration: none;
}
.button_white {
  background: #ffffff;
  border: 1px solid #ffffff;
  color: #323a45;
  transition: 0.25s ease;
}
.button_white:hover {
  background: #aacd21;
  border-color: #aacd21;
  color: #ffffff;
  transition: 0.25s ease;
}
.button_green {
  border: 1px solid #aacd21;
  color: #aacd21;
  background: #fff;
  transition: 0.25s ease-in-out;
}
.button_green:hover {
  background: #aacd21;
  color: #ffffff;
  transition: 0.25s ease-in-out;
}
.button_green-filled {
  border: 1px solid #aacd21;
  color: #ffffff;
  background: #aacd21;
  transition: 0.25s ease-in-out;
}
.button_green-filled:hover {
  border-color: #ffffff;
  background: #ffffff !important;
  color: #aacd21;
  transition: 0.25s ease-in-out;
}
.initial-letter {
  max-height: 143px;
  font-size: 200px;
  font-weight: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #f0f0f0;
}
.text {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.44;
  letter-spacing: normal;
  color: #323a45;
}
.text p + p {
  margin-top: 20px;
}
.text_left {
  text-align: left;
}
.text_right {
  text-align: right;
}
.subtitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  text-align: left;
  color: #323a45;
}
.slick-slide {
  outline: none;
}
.text-light {
  font-weight: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #797a7d;
}
.text-light_16 {
  font-size: 16px;
}
.text-light_18 {
  font-size: 18px;
  font-weight: 300;
}
.text-center {
  text-align: center;
}
.link {
  text-decoration: none;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.44;
  letter-spacing: normal;
  text-align: center;
  color: #7baf33;
}
.dots-bg {
  position: relative;
}
.dots-bg:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  height: 577px;
  max-height: 50%;
  background: url("/public/img/services-bg.png") no-repeat center bottom;
  background-size: cover;
  opacity: 0.4;
}
/*
navigation
*/
.navigation {
  height: 100%;
}
.navigation__item a {
  color: #323a45;
}
.navigation__item a:hover {
  color: #aacd21;
}
body {
  position: relative;
  background: #ffffff;
  overflow-x: hidden;
}
.main {
  position: relative;
  min-height: 100vh;
  padding-bottom: 187px;
}
input {
  display: block;
  background: transparent;
  border: none;
}
/*
navigation
*/
.hidden-container__button.rotate {
  transform: rotate(180deg);
}
.navigation__button {
  position: relative;
  width: 54px;
  min-width: 54px;
  height: 54px;
  border-radius: 100%;
  cursor: pointer;
}
.navigation__button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 24px;
  display: block;
  width: 0;
  height: 0;
  border: 6px solid #d5d5d5;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-right: 0;
  transform: translate(-50%, -50%);
}
.navigation__button_prev {
  transition: 0.25s ease;
}
.navigation__button_prev:after {
  left: 25px;
  border: 6px solid #d5d5d5;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left: 0;
  transition: 0.25s ease;
}
.navigation__button_prev:hover {
  border-color: #aacd21;
  transition: 0.25s ease;
}
.navigation__button_prev:hover:after {
  border-right-color: #aacd21;
  transition: 0.25s ease;
}
.navigation__button_next:after {
  left: 28px;
  transition: 0.25s ease;
}
.navigation__button_next:hover:after {
  border-left-color: #aacd21;
  transition: 0.25s ease;
}
.navigation__counter {
  font-size: 20px;
  font-weight: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: center;
  color: #808184;
}
.navigation__counter-current {
  font-size: 26px;
  font-weight: 500;
  color: #323a45;
}
.circle-bg {
  fill: rgba(255, 255, 255, 0);
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: butt;
}
.circle-go {
  fill: rgba(255, 255, 255, 0);
  stroke: #aacd21;
  stroke-width: 2;
  stroke-linecap: round;
}
/*
mobile-menu
*/
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  min-height: 100vh;
  height: 100%;
  background: #f0f0f0;
  width: 100%;
  transform: translateY(-100%);
  transition: 0.25s ease;
}
.mobile-menu__wrapper {
  padding: 90px 50px 60px;
}
.mobile-menu .navigation {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-bottom: 35px;
}
.mobile-menu .navigation__list {
  width: 33%;
  margin-top: 75px;
}
.mobile-menu .menu-item {
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}
.mobile-menu .telephone {
  padding: 35px 0;
  border-top: 1px solid #e8e8e8;
  justify-content: flex-start;
}
.mobile-menu.opened {
  transform: translateY(0);
  transition: 0.5s ease-in-out;
}
/*
header
*/
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 0;
  background: rgba(240, 240, 240, 0.9);
}
.header__nav a {
  position: relative;
  display: block;
  padding-bottom: 3px;
  overflow: hidden;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  color: #323a45;
}
.header__nav a:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0%;
  width: 50%;
  height: 3px;
  object-fit: contain;
  background-color: #acd021;
  transform: translateY(100%);
  transition: 0.25s ease-in-out;
}
.header__nav a:hover:before {
  transform: translateY(0);
  transition: 0.25s ease-in-out;
}
.header__tel {
  display: flex;
  align-items: center;
}
.header__burger {
  position: relative;
  z-index: 999;
  width: 38px;
  height: 24px;
  border-top: 1px solid #aacd21;
  border-bottom: 1px solid #aacd21;
  cursor: pointer;
  transition: 0.25s ease;
}
.header__burger:before,
.header__burger:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: block;
  height: 1px;
  width: 100%;
  background: #aacd21;
  transition: 0.25s ease;
}
.header__burger.opened {
  border-color: transparent;
  transition: 0.25s ease;
}
.header__burger.opened:before {
  transform: rotate(45deg);
  transition: 0.25s ease;
}
.header__burger.opened:after {
  transform: rotate(-45deg);
  transition: 0.25s ease;
}
.telephone {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.telephone a {
  font-size: 16px;
  font-weight: 300;
  color: #323a45;
  transition: 0.25s ease-in-out;
}
.telephone a span {
  font-size: 20px;
  font-weight: normal;
}
.telephone a:hover {
  color: #aacd21;
  transition: 0.25s ease-in-out;
}
/*
main banner
*/
.mainbanner {
  position: relative;
  height: 860px;
  overflow: hidden;
}
.mainbanner__content,
.mainbanner .slick-list,
.mainbanner .slick-track {
  height: 860px;
}
.mainbanner__item {
  position: relative;
  z-index: 2;
  min-height: 100%;
  padding: 17vh 0 0;
  background-color: #272a2f;
  overflow: hidden;
}
.mainbanner__item .title,
.mainbanner__item .subtitle {
  color: #ffffff;
}
.mainbanner__item .title {
  max-width: 700px;
  text-transform: uppercase;
  transition: transform 1s ease-in-out;
}
.mainbanner__item .subtitle {
  opacity: 0;
  margin: 12px 0 24px;
  transition: 0.25s ease-in-out;
}
.mainbanner__item .button {
  opacity: 0;
  transition: 0.25s ease-in-out;
}
.mainbanner__item:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: #272a2f;
  opacity: 0.8;
}
.mainbanner__item-full {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  z-index: 0;
  object-fit: cover;
}
.mainbanner__item-small {
  position: absolute;
  bottom: 0;
  left: calc(50% + 15px);
  width: 66%;
  height: 54%;
  object-fit: cover;
  object-position: top center;
  opacity: 0;
  transform: translate(-50%, 0);
  transition: 0.25s ease-in-out;
}
.mainbanner__item-container {
  position: relative;
  z-index: 3;
  height: 100%;
}
.mainbanner__navigation {
  position: absolute;
  top: 17vh;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 585px;
}
.mainbanner__navigation .slick-dots li {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 0px;
  cursor: pointer;
}
.mainbanner__navigation .slick-dots li svg {
  display: block !important;
  width: 60px;
  height: 60px;
  opacity: 0;
  transition: 0.1s ease-in-out;
}
.mainbanner__navigation .slick-dots li:before {
  content: '';
  position: absolute;
  top: 16.5px;
  left: 16.5px;
  transform: translate(-50%, -50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: #ffffff;
}
.mainbanner__navigation .slick-dots li.slick-active svg {
  opacity: 1;
  transition: 0.1s ease-in-out;
}
.mainbanner .slick-current .ml12 {
  opacity: 1;
}
.mainbanner .slick-current .subtitle {
  opacity: 1;
  transition: 0.75s ease-in-out;
  transition-delay: 1.75s;
}
.mainbanner .slick-current .button {
  opacity: 1;
  transition: background-color 0.25s ease-in-out, opacity 0.75s ease-in-out 1.75s, transform 1.5s ease-in-out;
}
.mainbanner .slick-current .mainbanner__item-small {
  opacity: 1;
  transition: 0.75s ease-in-out;
  transition-delay: 1.75s;
}
/*
left-image
*/
.left-image {
  padding: 144px 0 70px 0px;
}
.left-image_green {
  background: #aacd21;
}
.left-image_green .title,
.left-image_green .subtitle {
  max-width: 390px;
  color: #ffffff;
}
.left-image_green .title_left-line:before,
.left-image_green .subtitle_left-line:before {
  background: #ffffff;
}
.left-image_green.animated.visible .initial-letter {
  opacity: 0.4;
}
.left-image_reverse {
  padding: 123px 0 144px 0px;
}
.left-image_reverse .left-image__image img {
  width: 50vw;
  max-width: unset;
  max-height: 100%;
}
.left-image__image {
  padding-left: 67px;
}
.left-image__image img {
  max-width: 100%;
}
.left-image__subtitle {
  margin: 24px 0 36px;
}
.left-image .title {
  margin-bottom: 20px;
}
/*
success
*/
.success {
  position: relative;
  padding: 86px 0;
  overflow-x: hidden;
}
.success .circle-bg {
  stroke: rgba(0, 0, 0, 0.1);
}
.success__top {
  padding: 0 15px 48px 67px;
}
.success__navigation {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success__counter {
  margin: 0 25px;
}
.success__stories {
  margin-top: 48px;
}
.success__stories .slick-list {
  overflow: visible;
}
.success__stories .slick-track {
  display: flex;
}
.success__stories .slick-track .slick-slide {
  display: flex;
  height: auto;
  max-height: 725px;
}
.success .title {
  padding-left: 67px;
}
.hidden-list__title {
  position: relative;
  padding-left: 35px;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.36;
  text-align: left;
  color: #71777f;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}
.hidden-list__title .counter {
  position: absolute;
  top: 5px;
  left: 0;
  transform: rotate(-90deg);
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7baf33;
  transition: 0.25s ease-in-out;
}
.hidden-list__title:hover {
  color: #7baf33;
  transition: 0.25s ease-in-out;
}
.hidden-list__title:hover .counter {
  color: #7baf33;
  transition: 0.25s ease-in-out;
}
.hidden-list__item {
  margin-bottom: 12px;
}
.hidden-list__item.opened .hidden-list__arrow svg {
  transform: scaleY(-1);
}
.hidden-list__item.opened .hidden-list__text {
  max-height: 999px;
  transition: 1s ease;
}
.hidden-list__text {
  max-height: 0px;
  overflow: hidden;
  transition: 0.25s ease;
  margin-top: 12px;
  padding-left: 34px;
}
.hidden-list__arrow {
  cursor: pointer;
}
.hidden-container__text {
  position: relative;
  max-height: 380px;
  overflow: hidden;
  transition: 0.25s ease;
}
.hidden-container__text:before {
  content: '';
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0), #ffffff);
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.001) 0%, #fff 90%, #fff 100%);
  transition: 0.25s ease;
}
.hidden-container__button {
  display: flex;
  justify-content: center;
}
.hidden-container__button svg {
  width: 18px;
  height: 9px;
  cursor: pointer;
  opacity: 0.5;
}
.hidden-container__button.hidden {
  display: none;
}
.hidden-container.opened .hidden-container__text {
  max-height: 9999px;
  transition: 0.25s ease;
}
.hidden-container.opened .hidden-container__text:before {
  visibility: none;
  opacity: 0;
  transition: 0.25s ease;
}
.hidden-container.opened .hidden-container__button {
  display: none;
}
.hidden-container.opened .hidden-container__button.hidden {
  display: flex;
}
.hidden-container.opened .hidden-container__button svg {
  opacity: 0.5;
}
/* story */
.story {
  display: flex !important;
  overflow: visible;
}
.story .slick-list {
  overflow-x: visible;
}
.story__list {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
.story__info {
  max-height: 100%;
  padding-top: 72px;
  padding-bottom: 380px;
  padding-right: 50px;
}
.story__title {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.18;
  text-align: right;
  color: #323a45;
}
.story__description {
  margin: 36px 0 96px;
  text-align: right;
  line-height: 1.67;
}
.story__item {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.story__item:nth-child(even) .story__item-img {
  background: #f0f0f0;
}
.story__item:nth-child(even) .story__item-img:before {
  background: #aacd21;
}
.story__item-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 96px;
  height: 96px;
  background-color: #aacd21;
}
.story__item-img img {
  max-width: 50px;
  max-height: 50px;
  object-fit: contain;
}
.story__item-img:before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 1px;
  background-color: #f0f0f0;
}
.story__item-text {
  width: 100%;
  padding: 0 28px 0 80px;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.25;
  text-align: right;
  color: #797a7d;
}
.story__img {
  max-height: 100%;
  padding-left: 45px;
}
.story__img img {
  width: 50vw;
  max-width: 50vw;
  height: 725px;
  max-height: 100%;
  object-fit: cover;
}
/*
  services
*/
.services {
  padding: 144px 0 48px;
  background: #f0f0f0;
  overflow-y: hidden;
}
.services__list {
  margin-top: 180px;
}
.services__item {
  margin-bottom: 36px;
  overflow: hidden;
}
.services__title {
  position: relative;
  padding-left: 33px;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #323a45;
  text-transform: uppercase;
}
.services__counter {
  position: absolute;
  top: 6px;
  left: 0;
  transform: rotate(-90deg);
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #323a45;
}
.services__text {
  padding-left: 12px;
  line-height: 1.67;
}
.services_desktop .services__title {
  opacity: 0.2;
  transform: translateX(-100%);
  transition: 0.25s ease-in-out;
}
.services_desktop .services__text {
  opacity: 0.2;
  transform: translateX(-100%);
  transition: 0.25s ease-in-out;
}
.services_desktop .services__item.visible .services__title,
.services_desktop .services__item.visible .services__text {
  opacity: 1;
  transform: translateX(0%);
  transition: transform 1.5s cubic-bezier(0, 0, 0.3, 1), opacity 0.75s ease-in-out 0.75s;
}
.services_desktop .services__item.visible .services__text {
  transition-delay: 0.5s;
}
.services__column:last-child {
  padding-left: 0;
}
/*
key-industries
*/
.key-industries {
  padding: 96px 0 108px;
  background: #aacd21;
}
.key-industries__title {
  color: #ffffff;
  text-align: center;
}
.key-industries__container {
  position: relative;
  padding: 72px 67px 0;
}
.key-industries__image {
  display: block;
  max-width: 50px;
  max-height: 50px;
  object-fit: contain;
  margin: 0 auto 24px;
}
.key-industries__name {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.key-industries__navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
}
.key-industries__navigation .navigation__button {
  border-color: #ffffff;
  width: 44px;
  height: 44px;
  min-width: 44px;
}
.key-industries__navigation .navigation__button_next:after {
  border-left-color: #ffffff;
  left: 24px;
}
.key-industries__navigation .navigation__button_next:hover:after {
  border-left-color: #4d8108;
}
.key-industries__navigation .navigation__button_prev:after {
  left: 21px;
  border-right-color: #ffffff;
}
.key-industries__navigation .circle-go {
  stroke: #68a224;
  stroke-width: 3;
}
.key-industries__navigation .circle-bg {
  stroke: #ffffff;
  stroke-width: 3;
}
/*
team
*/
.team {
  position: relative;
  padding: 108px 0;
  padding-bottom: 80px;
}
.team__subtitle {
  margin: 36px 0 24px;
  margin-bottom: 8px;
}
.team__container {
  position: relative;
  z-index: 1;
  padding: 0 30px;
}
.team__main {
  position: relative;
  z-index: 1;
}
.team__main .slick-track {
  display: flex;
}
.team__additional {
  position: absolute !important;
  top: calc(50% - 80px);
  right: 140px;
  transform: translateY(-50%);
  z-index: 0;
  width: 312px;
  height: 432px;
  overflow: hidden;
}
.team__additional-img {
  width: 100%;
  height: 100%;
  height: 432px !important;
  object-fit: cover;
}
.team__additional .slick-list:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.6;
  z-index: 1;
}
.team__navigation {
  position: absolute;
  top: 343px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  z-index: 50;
  padding: 0 20px;
  pointer-events: none;
}
.team__navigation .navigation__button_prev {
  pointer-events: all;
  transform: translateX(-15px);
}
.team__navigation .navigation__button_next {
  pointer-events: all;
  transform: translateX(15px);
}
.team__navigation .circle-bg {
  stroke: #f0f0f0;
}
.team .title {
  line-height: 0.8;
}
/*
member
*/
.member {
  outline: none;
}
.member.slick-slide {
  height: auto;
}
.member__wrapper {
  display: flex;
  justify-content: flex-start;
  min-height: 100%;
  padding-top: 48px;
}
.member__photo {
  position: relative;
  z-index: 1;
  width: 555px;
  max-width: 100%;
  height: 576px;
  margin-bottom: 36px;
  object-fit: cover;
  pointer-events: none;
}
.member__photo-next {
  display: none;
  top: calc(50% - 100px);
  transform: translateY(-50%);
  z-index: 0;
  width: 312px;
  height: 432px;
}
.member__num {
  position: relative;
  display: inline-block;
  height: 144px;
  margin: 0 0 32px auto;
  font-size: 200px;
  font-weight: normal;
  line-height: 0.29;
  text-align: right;
  color: #f0f0f0;
}
.member__num:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 96px;
  background: #aacd21;
}
.member__name {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: right;
  color: #323a45;
}
.member__position {
  margin: 4px 0 258px;
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: right;
  color: #323a45;
}
.member__left {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 290px;
  padding-right: 56px;
}
.member__right {
  position: relative;
  width: 555px;
}
.member__blockquote {
  position: relative;
  padding-left: 77px;
  transform: translateX(38%);
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #71777f;
}
.member__blockquote:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 64px;
  height: 54px;
  background: url("/public/img/quote.svg") no-repeat center center;
  background-size: contain;
}
.member__link {
  padding-right: 40px;
  position: absolute;
  bottom: 250px;
}
.member__link:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  width: 30px;
  height: 100%;
  background: url("/public/img/arrow.svg") no-repeat center center;
  background-size: 100%;
  transition: 0.25s ease-in-out;
}
.member__link:hover:before {
  transform: translate(25%, -50%);
  transition: 0.25s ease-in-out;
}
/*
audience
*/
.audience {
  padding: 84px 0 96px;
  background: #f0f0f0;
}
.audience__item:nth-child(odd) {
  text-align: right;
}
.audience__item:nth-child(odd) .audience__text {
  padding-left: 38px;
}
.audience__item:nth-child(even) .audience__text {
  padding-right: 38px;
}
.audience__item {
  margin-top: 24px;
  margin-top: 48px;
}
.audience__item img {
  max-width: 100%;
}
.audience__name {
  margin: 24px 0 12px;
  font-size: 20px;
  font-weight: normal;
  line-height: 1.5;
  color: #323a45;
}
/*
ebrr-cooperation
*/
.ebrr-cooperation {
  padding: 96px 0 108px;
}
.ebrr-cooperation .title {
  line-height: 0.8;
}
.ebrr-cooperation__subtitle {
  max-width: 520px;
  margin: 24px auto 0;
  color: #71777f;
}
.ebrr-cooperation .grey-card__image {
  max-width: 175px;
  margin-bottom: 24px;
}
.grey-card {
  max-width: 683px;
  margin: 48px auto 0;
  padding: 48px 55px 56px;
  background: #f0f0f0;
  text-align: center;
}
/*
reviews
*/
.reviews {
  padding: 108px 0;
  background: #f0f0f0;
}
.reviews__container {
  position: relative;
}
.reviews__list {
  position: relative;
  z-index: 2;
  margin-top: 28px;
}
.reviews__navigation {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}
.review {
  display: flex !important;
}
.review__image {
  width: 50%;
}
.review__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}
.review__info {
  width: 50%;
  padding: 36px 0 0 44px;
}
.review__name {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.36;
  color: #323a45;
}
.review__industry {
  position: relative;
  margin: 24px 0;
  padding-right: 64px;
  font-family: 'Roboto Lt';
  font-size: 18px;
  font-weight: 300;
  font-style: italic;
  text-align: left;
  color: #959595;
}
.review__industry:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 64px;
  height: 54px;
  background: url("/public/img/quote-white.svg") no-repeat center center;
  background-size: contain;
}
.review__position {
  margin: 24px 0 36px;
}
.review__link {
  position: relative;
  display: inline-block;
  margin-top: 36px;
}
.review__link:before {
  content: '';
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(5%, -50%);
  width: 30px;
  height: 100%;
  background: url("/public/img/arrow.svg") no-repeat center center;
  background-size: 100%;
  transition: 0.25s ease-in-out;
}
.review__link:hover:before {
  transform: translate(25%, -50%);
  transition: 0.25s ease-in-out;
}
/*
cooperation
*/
.cooperation {
  padding: 96px 0 108px;
  padding-bottom: 68px;
}
.cooperation__subtitle {
  max-width: 650px;
  margin: 24px auto 38px;
}
/*
form
*/
.form__field {
  box-sizing: border-box;
  position: relative;
  min-height: 48px;
  width: 100%;
  margin-bottom: 24px;
  padding-top: 12px;
  overflow: hidden;
}
.form__field:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: #aacd21;
  height: 1px;
  transform: translateX(-100%);
  transition: 0.25s ease;
}
.form__field:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #cccccc;
  height: 1px;
}
.form__field.error {
  border-bottom-color: #dd2429;
}
.form__field.error .form__error {
  display: block;
}
.form__field input,
.form__field textarea {
  width: 100%;
  height: 36px;
  padding-right: 13px;
  outline: none;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.44;
  color: #959595;
  border: none;
}
.form__field input::-webkit-input-placeholder,
.form__field textarea::-webkit-input-placeholder {
  color: #959595;
  transition: all 0.25s ease;
}
.form__field input::-moz-placeholder,
.form__field textarea::-moz-placeholder {
  color: #959595;
  transition: all 0.25s ease;
}
.form__field input:-moz-placeholder,
.form__field textarea:-moz-placeholder {
  color: #959595;
  transition: all 0.25s ease;
}
.form__field input:focus::-webkit-input-placeholder,
.form__field textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
.form__field input:focus::-moz-placeholder,
.form__field textarea:focus::-moz-placeholder {
  color: transparent;
}
.form__field input:-moz-placeholder,
.form__field textarea:-moz-placeholder {
  color: transparent;
}
.form__field textarea {
  min-height: 72px;
  resize: none;
  padding-top: 7px;
  background: transparent;
  overflow: hidden;
}
.form__field_toggle {
  display: flex;
  justify-content: flex-start;
  margin-top: -12px;
}
.form__field_toggle input[type='checkbox']:checked ~ .form__checkbox {
  border-color: #aacd21;
  translate: 0.25s ease-in-out;
}
.form__field_toggle input[type='checkbox']:checked ~ .form__checkbox:before {
  transform: scale(1);
  translate: 0.25s ease-in-out;
}
.form__field_toggle:after {
  display: none;
}
.form__field .form__field-element_checkbox input[type="checkbox"] {
  width: 1px;
  height: 1px;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -99999px;
  left: 99999px;
}
.form__field .form__field-element_checkbox input[type="checkbox"]:checked + label:before {
  transform: scale(1);
}
.form__field .form__field-label {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #959595;
}
.form__field .form__field-label a {
  color: inherit;
  text-decoration: underline;
}
.form__field .error_message {
  display: none;
  opacity: 0;
  font-size: 12px;
  margin-top: -16px;
  margin-left: 32px;
  color: #cc5b3c;
}
.form__field.error_field .error_message {
  display: block;
  opacity: 1;
  position: absolute;
}
.form__field.error_field .form__checkbox {
  border-color: red;
}
.form__field.focused .form__label,
.form__field.filled .form__label {
  opacity: 1;
  visibility: visible;
  transform: translateY(0%);
  transition: 0.25s ease-in-out;
}
.form__field.focused:before,
.form__field.filled:before {
  transform: translateX(0%);
  transition: 0.5s ease;
}
.form__checkbox {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 17px;
  height: 17px;
  margin: 0 10px 0 0;
  border: solid 1px #878a8f;
  cursor: pointer;
  translate: 0.25s ease-in-out;
}
.form__checkbox:before {
  content: '';
  width: 9px;
  height: 9px;
  background: #aacd21;
  background-size: contain;
  transform: scale(0);
  translate: 0.25s ease-in-out;
}
.form__label {
  position: absolute;
  top: 0;
  left: 0px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100%);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.86;
  text-align: left;
  color: #b9b9b9;
  transition: 0.25s ease-in-out;
}
.form__error {
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.86;
  letter-spacing: normal;
  text-align: right;
  color: #dd2429;
}
.form__button {
  display: flex;
  justify-content: center;
  margin-top: 26px;
}
.form__field-clear {
  position: relative;
  width: 11px;
  height: 11px;
  margin: 0 0 0 auto;
  cursor: pointer;
}
.form__field-clear:before,
.form__field-clear:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 1px;
  height: 100%;
  background: #dd2429;
}
.form__field-clear:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.form__field-clear:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.success__image {
  text-align: center;
  margin-bottom: 30px;
}
.success__image svg {
  width: 100px;
  height: 100px;
}
.success__image svg path {
  fill: #aacd21;
}
.success__text {
  font-size: 24px;
  font-weight: 500;
  color: #aacd21;
  text-transform: uppercase;
  text-align: center;
}
.success__text_error {
  color: #878a8f;
}
.cooperation__form {
  max-width: 500px;
  margin: 24px auto 36px;
}
/*
contacts
*/
.contacts {
  position: relative;
  padding: 120px 0 96px;
}
.contacts:after {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1px;
  height: 96px;
  background: #aacd21;
}
.contacts__map {
  max-width: 100%;
  width: 810px;
  height: 444px;
  margin: 48px auto 36px;
}
.contacts:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f0f0f0;
  display: block;
  height: 40%;
}
.contacts__bottom {
  display: flex;
  justify-content: space-between;
}
.contacts__item {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 36%;
  padding-left: 10px;
}
.contacts__icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  max-width: 25px;
  max-height: 35px;
  object-fit: contain;
}
.contacts__text {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  text-align: left;
  color: #323a45;
  transition: 0.25s ease;
}
.contacts a.contacts__text:hover {
  color: #aacd21;
  transition: 0.25s ease;
}
/*
modals
*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(50, 58, 69, 0.7);
}
.overlay.visible {
  visibility: visible;
  opacity: 1;
}
.modal-container {
  display: none;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75%;
  max-width: 1070px;
  margin: 0 auto 0;
  padding: 90px 135px;
  background: #ffffff;
}
.modal-container__info {
  width: 60%;
  padding-left: 30px;
}
.modal-container__img {
  width: 40%;
}
.modal-container__img img {
  width: 100%;
  height: auto;
  min-height: 55%;
}
.modal-container__info p,
.modal-container__info li,
.modal-container__info a {
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.67;
  text-align: left;
  color: #71777f;
}
.modal-container__info ul {
  padding-left: 20px;
  margin-bottom: 1rem;
}
.modal-container__info li {
  list-style: disc;
}
.modal-container__info .subtitle {
  margin-top: 0.5rem;
  margin-bottom: 1.25rem;
}
.modal-container__close {
  position: absolute;
  top: 25px;
  right: 37px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.modal-container__close:before,
.modal-container__close:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 100%;
  background: #aacd21;
}
.modal-container__close:before {
  transform: translate(-50%, -50%) rotate(-45deg);
}
.modal-container__close:after {
  transform: translate(-50%, -50%) rotate(45deg);
}
.modal-container_form .text-center {
  margin: 0 auto 1rem;
}
.modal-container.visible {
  display: flex;
  flex-wrap: wrap;
  z-index: 9999;
}
.modal-container.visible.modal-container_form {
  position: fixed;
  top: 50%;
}
.modal-container_review .modal-container__info {
  width: 100%;
  padding-left: 0;
}
.modal-container_bg:before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 232px;
  background: url("/public/img/popup-bg.png") no-repeat center bottom;
}
/*
footer
*/
.fixed-button__btn {
  position: fixed;
  left: 50%;
  bottom: 15vh;
  transform: translate(-50%, 0);
  z-index: 5;
  display: flex;
  align-items: center;
  margin: 0 0 0 auto;
}
.fixed-button__btn div {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 82px;
  height: 82px;
  border-radius: 50%;
  background-color: #81b03c;
  animation: shadow-pulse 1.5s infinite linear;
}
.fixed-button__btn span {
  position: absolute;
  top: 22px;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 240px;
  max-width: 0;
  overflow: hidden;
  height: 44px;
  padding: 0 0 0 25px;
  background: #81b03c;
  transform: translateX(-40px);
  font-size: 16px;
  font-weight: normal;
  text-align: left;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 0;
  transition: 0.4s ease-in-out;
}
.fixed-button__btn:hover span {
  max-width: 999px;
  opacity: 1;
  transition: 0.4s ease-in-out;
}
@-webkit-keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px #aacd21;
  }
  30% {
    box-shadow: 0 0 0 16px rgba(170, 250, 33, 0.7);
  }
  100% {
    box-shadow: 0 0 0 38px rgba(170, 250, 33, 0.2);
  }
}
@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px #aacd21;
  }
  30% {
    box-shadow: 0 0 0 16px rgba(231, 220, 197, 0.7);
  }
  100% {
    box-shadow: 0 0 0 38px rgba(253, 249, 252, 0.2);
  }
}
.footer__top {
  padding: 60px 0;
  background: #323a45;
}
.footer__bottom {
  padding: 20px 0;
  background: #1f242c;
}
.footer__text,
.footer__text a {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.75;
  text-align: left;
  color: #ffffff;
  transition: 0.15s ease-in-out;
}
.footer__text:last-child,
.footer__text a:last-child {
  text-align: right;
}
.footer__text a:hover {
  color: #ff0048;
  transition: 0.15s ease-in-out;
}
.footer .menu-item {
  color: #ffffff;
}
.footer .menu-item:hover {
  color: #aacd21;
}
.footer .telephone a {
  color: #ffffff;
}
.footer .telephone a:hover {
  color: #aacd21;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #ffffff inset !important;
  color: #323a45 !important;
}
.animated .initial-letter {
  padding: 0 20px;
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.animated .fade-bottom {
  transform: translateY(30px);
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.animated .fade {
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.animated .fade-left {
  transform: translateX(-30px);
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.animated .fade-right {
  transform: translateX(30px);
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.animated.visible .initial-letter {
  padding: 0px;
  opacity: 1;
  transition: 0.5s ease-in-out;
}
.animated.visible .fade,
.animated.visible .fade-left,
.animated.visible .fade-right,
.animated.visible .fade-bottom {
  transform: translateY(0);
  opacity: 1;
  transition: 1.5s ease-in-out;
}
#about,
#cases,
#services,
#solution,
#industries,
#team,
#what-rain,
#ebrr-cooperation,
#reviews,
#cooperation,
#contacts,
.breadcrumbs,
.top-centered,
.transcending-content,
.timeline,
.centered-content,
.list-link,
.page404,
.team-slider {
  position: relative;
  z-index: 1;
}
#about .poloska,
#cases .poloska,
#services .poloska,
#solution .poloska,
#industries .poloska,
#team .poloska,
#what-rain .poloska,
#ebrr-cooperation .poloska,
#reviews .poloska,
#cooperation .poloska,
#contacts .poloska,
.breadcrumbs .poloska,
.top-centered .poloska,
.transcending-content .poloska,
.timeline .poloska,
.centered-content .poloska,
.list-link .poloska,
.page404 .poloska,
.team-slider .poloska {
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 0;
  width: 100%;
  height: 100%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-between;
}
#about .poloska_item,
#cases .poloska_item,
#services .poloska_item,
#solution .poloska_item,
#industries .poloska_item,
#team .poloska_item,
#what-rain .poloska_item,
#ebrr-cooperation .poloska_item,
#reviews .poloska_item,
#cooperation .poloska_item,
#contacts .poloska_item,
.breadcrumbs .poloska_item,
.top-centered .poloska_item,
.transcending-content .poloska_item,
.timeline .poloska_item,
.centered-content .poloska_item,
.list-link .poloska_item,
.page404 .poloska_item,
.team-slider .poloska_item {
  width: 1px;
  height: 100%;
  background: #e8e8e8;
}
#contacts .poloska {
  z-index: -1;
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
@media only screen and (min-width: 1441px) {
  .mainbanner__item {
    padding-top: 178px;
  }
  .mainbanner__navigation {
    top: 188px;
  }
}
@media only screen and (max-width: 1440px) {
  .mainbanner {
    height: 100vh;
    min-height: 0;
    max-height: none;
  }
  .mainbanner br {
    display: none;
  }
  .mainbanner__content,
  .mainbanner .slick-list,
  .mainbanner .slick-track {
    height: 100vh;
    min-height: 0;
    max-height: none;
  }
  .mainbanner__item-small {
    width: 60%;
    height: 66%;
  }
  .mainbanner__item .subtitle {
    font-size: 16px;
    margin-top: 7px;
  }
  .title {
    font-size: 24px;
    line-height: 24px;
  }
  .button {
    width: 228px;
    height: 44px;
  }
  .header {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  .mainbanner__navigation .slick-dots li {
    height: 28px;
  }
  .mainbanner__navigation .slick-dots li:before {
    top: 11.5px;
  }
}
@media only screen and (max-width: 1199px) {
  .modal-container {
    width: 80%;
    padding: 50px 70px;
    max-height: 100vh;
    overflow: scroll;
  }
  .mainbanner {
    height: 100vh;
    min-height: 576px;
    overflow: hidden;
  }
  .mainbanner__content,
  .mainbanner .slick-list,
  .mainbanner .slick-track {
    height: 100vh;
    min-height: 576px;
  }
  .mainbanner__navigation {
    width: 480px;
    top: 100px;
  }
  .mainbanner__item {
    padding-top: 102px;
  }
  .mainbanner__item-small {
    height: 50vh;
    min-height: 320px;
  }
  .mainbanner .title {
    font-size: 28px;
    line-height: 28px;
  }
  .mainbanner .subtitle {
    margin: 24px 0 36px;
  }
  .initial-letter {
    max-height: 100px;
    font-size: 150px;
  }
  .telephone {
    justify-content: center;
  }
  .telephone a {
    font-size: 16px;
  }
  .telephone a span {
    font-size: 18px;
  }
  .header {
    height: 72px;
  }
  .header__nav a {
    font-size: 18px;
  }
  .header__logo {
    max-height: 100%;
  }
  .header__logo img {
    width: 147px;
    height: 51px;
    margin: 0 auto 0 0;
  }
  .left-image {
    padding: 84px 0 36px;
  }
  .success {
    padding: 72px 0;
  }
  .story__info {
    padding-right: 15px;
    padding-top: 48px;
  }
  .story__list {
    right: 15px;
  }
  .story__item-text {
    padding-left: 15px;
  }
  .story__description {
    margin: 24px 0 48px;
  }
  .story__img img {
    height: 100%;
  }
  .hidden-list__title {
    font-size: 20px;
  }
  .hidden-list__item {
    margin-bottom: 15px;
  }
  .team__additional {
    right: 100px;
  }
  .member__left {
    width: 280px;
  }
  .member__right {
    width: 410px;
  }
  .member__photo {
    width: 410px;
  }
  .member__link {
    bottom: 320px;
  }
  .services {
    padding-top: 72px;
  }
  .services__list {
    margin-top: 120px;
  }
  .team,
  .key-industries,
  .audience,
  .ebrr-cooperation,
  .reviews {
    padding: 72px 0 86px;
  }
  .review__list {
    margin-top: 36px;
  }
  .cooperation {
    padding: 72px 0;
  }
  .contacts {
    padding-bottom: 60px;
  }
  .contacts__text {
    font-size: 16px;
  }
  .footer__top {
    padding: 36px 0;
  }
  .footer .menu-item {
    font-size: 18px;
  }
}
@media only screen and (max-width: 991px) {
  .navigation__counter {
    margin: 0 25px;
  }
  .mainbanner br {
    display: block;
  }
  .header {
    padding: 11px 0;
  }
  .title {
    font-size: 28px;
    line-height: 28px;
  }
  .title_left-right .initial-letter {
    transform: translate(0, -50%);
  }
  .title_left-right:before {
    width: 76px;
    top: 50%;
    right: 40px;
  }
  .title_left-line:before {
    width: 76px;
    margin-right: 25px;
  }
  .button {
    width: 170px;
    height: 34px;
    font-size: 16px;
  }
  .subtitle {
    font-size: 18px;
  }
  .text {
    font-size: 16px;
    line-height: 1.5;
  }
  .text-light_18 {
    font-size: 16px;
    line-height: 1.5;
  }
  .link {
    font-size: 16px;
  }
  .mainbanner {
    height: 576px;
  }
  .mainbanner__navigation {
    top: 114px;
    width: 360px;
  }
  .mainbanner__navigation .slick-dots li {
    width: 30px;
    height: 30px;
  }
  .mainbanner__navigation .slick-dots li:before {
    top: 12.5px;
    left: 12.5px;
    width: 4px;
    height: 4px;
  }
  .mainbanner__content,
  .mainbanner .slick-list,
  .mainbanner .slick-track {
    height: 576px;
  }
  .mainbanner__item {
    padding-top: 116px;
  }
  .mainbanner__item-container {
    padding-bottom: 0px;
  }
  .mainbanner__item-small {
    min-height: unset;
    height: 250px;
  }
  .mainbanner .title {
    font-size: 24px;
    line-height: 24px;
  }
  .mainbanner .subtitle {
    margin: 4px 0 16px;
  }
  .left-image {
    padding: 72px 0 60px;
  }
  .left-image__image {
    padding-left: 25px;
    padding-right: 65px;
  }
  .left-image__text {
    padding-left: 55px;
  }
  .left-image__subtitle {
    margin: 12px 0 36px;
    font-size: 16px;
  }
  .success {
    padding: 36px 0 72px;
  }
  .success .title {
    padding-left: 45px;
    padding-right: 125px;
    text-align: right;
  }
  .success__top {
    padding-left: 42px;
  }
  .success__top > .row {
    align-items: center;
  }
  .success__stories {
    margin-top: 0px;
  }
  .story__list {
    bottom: 30px;
  }
  .services .container {
    position: relative;
  }
  .services .services__list {
    margin-left: 75px;
    margin-right: 75px;
  }
  .services__item {
    margin-bottom: 0;
  }
  .services__navigation {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    height: 50px;
  }
  .services__navigation .navigation__button_prev,
  .services__navigation .navigation__button_next {
    width: 50px;
    height: 50px;
  }
  .services__navigation .navigation__button_prev .circle-bg,
  .services__navigation .navigation__button_next .circle-bg {
    stroke: #959595;
  }
  .services__navigation .navigation__button_prev:after,
  .services__navigation .navigation__button_next:after {
    border-left-color: #959595;
    border-right-color: #959595;
  }
  .services__navigation .navigation__button_prev:hover .circle-bg,
  .services__navigation .navigation__button_next:hover .circle-bg {
    border-color: #aacd21;
  }
  .services__navigation .navigation__button_prev:hover:after,
  .services__navigation .navigation__button_next:hover:after {
    border-left-color: #aacd21;
    border-right-color: #aacd21;
  }
  .services__navigation .navigation__button_next:after {
    left: 25px;
  }
  .services__navigation .navigation__button_prev {
    left: 14px;
  }
  .services__navigation .navigation__button_next {
    right: 14px;
  }
  .services__title {
    font-size: 20px;
    line-height: 1.5;
  }
  .hidden-list {
    margin-left: -35px;
  }
  .hidden-list__title {
    font-size: 18px;
    line-height: normal;
  }
  .hidden-list__title .counter {
    top: 3px;
  }
  .hidden-list__text {
    margin-top: 5px;
    font-size: 14px;
  }
  .key-industries {
    padding: 72px 0 60px;
  }
  .key-industries__container {
    padding-top: 36px;
  }
  .key-industries__navigation {
    padding: 0;
  }
  .team {
    padding: 72px 0;
  }
  .team__main {
    max-width: 83.333333%;
    margin: 0 auto;
  }
  .team__subtitle {
    margin: 24px 0 0;
  }
  .team__additional {
    top: 450px;
    right: 65px;
    width: 286px;
    height: 350px;
  }
  .team__additional-img {
    height: 350px !important;
  }
  .team__navigation {
    padding: 0;
  }
  .member__wrapper {
    flex-direction: column;
  }
  .member__left {
    position: relative;
    align-items: center;
    width: 100%;
    padding: 0 52px 0 100px;
  }
  .member__num {
    position: absolute;
    top: 0%;
    left: 52px;
    transform: translateY(0%);
    height: 100px;
    margin: 0;
    font-size: 150px;
    line-height: 100px;
    text-align: left;
    color: #f0f0f0;
  }
  .member__num:before {
    top: 50px;
    left: 50%;
    width: 76px;
    height: 1px;
  }
  .member__link {
    position: relative;
    bottom: unset;
  }
  .member__name {
    font-size: 20px;
  }
  .member__position {
    margin: 12px 0 36px;
    font-size: 16px;
  }
  .member__right {
    width: 100%;
    height: 100%;
    margin: 24px auto 0;
  }
  .member__photo {
    max-width: 400px;
    height: 500px;
    margin: 0 auto 38px;
  }
  .member__blockquote {
    padding: 0 52px 0 125px;
    font-size: 16px;
    line-height: 1.63;
    transform: none;
  }
  .member__blockquote:before {
    top: -11px;
    left: 62px;
    width: 52px;
    height: 45px;
  }
  .audience__name {
    margin: 24px 0 12px;
    font-size: 18px;
  }
  .audience__item {
    margin-top: 36px;
  }
  .audience__item:nth-child(odd) .audience__text,
  .audience__item:nth-child(even) .audience__text {
    padding: 0;
  }
  .ebrr-cooperation {
    padding: 72px 0;
  }
  .ebrr-cooperation__subtitle {
    font-size: 16px;
  }
  .grey-card {
    margin-top: 36px;
    padding: 36px 60px 48px;
  }
  .reviews {
    padding: 72px 0;
  }
  .reviews__list {
    margin-top: 36px;
  }
  .reviews__navigation {
    padding: 0;
  }
  .review {
    flex-direction: column;
  }
  .review__image,
  .review__info {
    width: 100%;
  }
  .review__info {
    padding-left: 0;
  }
  .review__name {
    font-size: 18px;
    line-height: 1.67;
  }
  .review__industry {
    margin: 12px 0;
    padding-right: 200px;
    font-size: 16px;
  }
  .review__position {
    margin: 0 0 24px;
    font-size: 16px;
  }
  .review__link {
    margin-top: 12px;
  }
  .cooperation {
    padding: 72px 0 36px;
  }
  .cooperation__subtitle {
    margin: 24px 0 12px;
    font-size: 16px;
  }
  .form__field {
    max-width: 415px;
    margin: 0 auto 24px;
  }
  .form__field input,
  .form__field textarea {
    font-size: 16px;
  }
  .form__button button {
    width: 170px;
    height: 36px;
    margin-top: 0px;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.63;
  }
  .contacts:after {
    height: 72px;
  }
  .contacts_page {
    padding-top: 100px;
  }
  .contacts__map {
    margin-top: 36px;
    padding: 0 27px;
    height: 408px;
  }
  .contacts__bottom {
    padding: 0 27px 0 42px;
  }
  .contacts__icon {
    width: 20px;
    max-height: 25px;
  }
  .footer__top {
    display: none;
  }
  .modal-container__img,
  .modal-container__info {
    width: 100%;
    padding-left: 0;
  }
  .modal-container__img img {
    display: block;
    margin: 0 auto 25px;
    width: auto;
    max-width: 100%;
    max-height: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .poloska {
    display: none !important;
  }
  .animated .initial-letter {
    padding: 0 20px;
    opacity: 1;
    transition: 0s ease-in-out;
  }
  .animated .fade-bottom {
    transform: translateY(0px);
    opacity: 1;
    transition: 0s ease-in-out;
  }
  .animated .fade {
    opacity: 1;
    transition: 0s ease-in-out;
  }
  .animated .fade-left {
    transform: translateX(0px);
    opacity: 1;
    transition: 0s ease-in-out;
  }
  .animated .fade-right {
    transform: translateX(0px);
    opacity: 1;
    transition: 0s ease-in-out;
  }
  .word {
    transform: translateY(0%);
    opacity: 1;
  }
  .navigation__counter {
    font-size: 16px;
  }
  .navigation__counter-current {
    font-size: 21px;
  }
  .navigation .navigation__button_prev,
  .navigation .navigation__button_next,
  .navigation__button {
    width: 38px;
    min-width: 38px;
    height: 38px;
  }
  .navigation .navigation__button_prev:after,
  .navigation .navigation__button_next:after {
    border-width: 5px;
  }
  .navigation .navigation__button_prev {
    transform: translateX(-15px);
  }
  .navigation .navigation__button_prev:after {
    top: 50%;
    left: 50%;
    transform: translate(-60%, -50%);
  }
  .navigation .navigation__button_next {
    transform: translateX(15px);
  }
  .navigation .navigation__button_next:after {
    top: 50%;
    left: 50%;
    transform: translate(-45%, -50%);
  }
  .fixed-button__btn div {
    right: 15px;
    width: 60px;
    height: 60px;
  }
  .modal-container__info p,
  .modal-container__info li,
  .modal-container__info a {
    font-size: 12px;
    line-height: normal;
  }
  .modal-container {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    padding: 40px 30px;
    overflow-y: scroll;
  }
  .modal-container__close {
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
  }
  .modal-container__close:before,
  .modal-container__close:after {
    height: 20px;
  }
  .initial-letter {
    height: 82px;
    font-size: 72px;
  }
  .title {
    font-size: 20px;
    line-height: 20px;
  }
  .title_left-line:before {
    top: 18px;
    right: unset;
    left: 0;
    transform: translateX(-50%);
    width: 35px;
    margin: 0;
  }
  .title_left-line .initial-letter {
    top: 50%;
    transform: translate(0, -50%);
  }
  .title_left-right:before {
    right: unset;
    left: 100%;
    width: 35px;
  }
  .title_left-right .initial-letter {
    right: unset;
    top: 50%;
    left: 100%;
  }
  .title_center .initial-letter {
    height: 52px;
    font-size: 72px;
  }
  .title_center:before {
    height: 35px;
  }
  .subtitle {
    font-size: 16px;
  }
  .text p + p {
    margin-top: 10px;
  }
  .text p {
    margin-bottom: 0;
  }
  .link {
    font-size: 14px;
  }
  .header {
    height: 60px;
    padding: 5px 0;
  }
  .header__logo {
    height: 45px;
  }
  .header__tel {
    display: none;
  }
  .mainbanner {
    height: 400px;
    min-height: 400px;
  }
  .mainbanner__content,
  .mainbanner .slick-list,
  .mainbanner .slick-track {
    height: 400px;
  }
  .mainbanner .subtitle {
    opacity: 1;
  }
  .mainbanner .button {
    opacity: 1;
  }
  .mainbanner__item {
    padding: 102px 0 72px;
  }
  .mainbanner__item-small {
    display: none !important;
  }
  .mainbanner__navigation {
    width: 270px;
  }
  .mainbanner .slick-current .subtitle {
    opacity: 1;
    transition: 0s ease-in-out;
    transition-delay: 0;
  }
  .left-image {
    padding: 48px 0 24px;
  }
  .left-image_reverse .left-image__text .title {
    padding-left: 0px;
  }
  .left-image_reverse .left-image__image img {
    width: 100%;
    max-width: 100%;
  }
  .left-image__image {
    padding-left: 15px;
    padding-right: 15px;
  }
  .left-image__subtitle {
    margin-bottom: 29px;
  }
  .left-image__text {
    padding-top: 34px;
    padding-left: 15px;
  }
  .left-image__text .title {
    padding-left: 65px;
    margin-bottom: 24px;
  }
  .hidden-container__text {
    max-height: 178px;
    font-size: 14px;
  }
  .success {
    padding: 24px 0 96px;
  }
  .success .title {
    padding: 15px 0;
  }
  .success__navigation {
    position: absolute;
    bottom: 48px;
    left: 0;
    right: 0;
    height: auto;
    width: 200px;
    margin: 0 auto;
  }
  .success__navigation .navigation__button_prev,
  .success__navigation .navigation__button_next,
  .success__navigation .navigation__button {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
  .success__stories {
    margin-top: 12px;
  }
  .story__info {
    padding-top: 0px;
    padding-bottom: 340px;
  }
  .story__title {
    font-size: 16px;
  }
  .story__description {
    margin: 12px 0 24px;
    font-size: 14px;
  }
  .story__item {
    justify-content: flex-end;
  }
  .story__list {
    bottom: 0;
  }
  .story__item-img {
    width: 87px;
    min-width: 87px;
    height: 87px;
  }
  .story__item-text {
    font-size: 14px;
    text-align: right;
  }
  .story__img {
    display: none;
  }
  .services {
    padding: 36px 0 12px;
  }
  .services__list {
    margin-top: 72px;
  }
  .services .services__list {
    margin-left: 30px;
    margin-right: 40px;
  }
  .services__title {
    padding-left: 20px;
    font-size: 14px;
  }
  .services__counter {
    position: static;
    transform: none;
    display: block;
    font-size: 16px;
  }
  .services__text {
    padding-left: 20px;
    font-size: 14px;
  }
  .hidden-list {
    margin-left: 0;
  }
  .hidden-list__title {
    padding-left: 25px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .hidden-list__title .counter {
    top: 0;
    font-size: 14px;
  }
  .hidden-list__text {
    padding-left: 0;
    font-size: 12px;
  }
  .key-industries {
    padding: 48px 0;
  }
  .key-industries__container {
    padding-top: 24px;
  }
  .key-industries__name {
    font-size: 16px;
  }
  .team {
    padding: 48px 0;
  }
  .team__subtitle {
    margin: 24px 0;
  }
  .team__additional {
    top: 330px;
    right: 45px;
    width: 200px;
    height: 250px;
  }
  .member__wrapper {
    padding-top: 24px;
  }
  .member__left {
    align-items: flex-start;
    padding-left: 75px;
  }
  .member__photo {
    width: 345px;
    height: 375px;
    margin: 0;
  }
  .member__name {
    font-size: 16px;
  }
  .member__num {
    top: 0;
    left: 0;
    transform: none;
    height: 53px;
    line-height: 40px;
    font-size: 72px;
  }
  .member__num:before {
    top: 22px;
    width: 35px;
  }
  .member__position {
    margin: 12px 0 12px;
    font-size: 14px;
  }
  .member__blockquote {
    padding: 24px 80px 0 35px;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.29;
  }
  .member__blockquote:before {
    top: 24px;
    left: 0;
    width: 24px;
    height: 24px;
  }
  .audience {
    padding: 48px 0;
  }
  .audience__wrapper {
    position: relative;
  }
  .audience__navigation {
    display: none;
    position: absolute;
    top: 100px;
    width: 100%;
  }
  .audience__navigation .navigation__button_prev,
  .audience__navigation .navigation__button_next {
    border-color: #959595;
  }
  .audience__navigation .navigation__button_prev:after,
  .audience__navigation .navigation__button_next:after {
    border-left-color: #959595;
    border-right-color: #959595;
  }
  .audience__navigation .navigation__button_prev {
    transform: translateX(-35px);
  }
  .audience__navigation .navigation__button_next {
    transform: translateX(35px);
  }
  .audience__navigation .circle-bg {
    stroke: #959595;
  }
  .audience__item,
  .audience__item:nth-child(odd) {
    margin: 0;
    text-align: center;
  }
  .audience__container {
    margin: 24px 30px 0;
  }
  .audience__name {
    margin: 24px 0 12px;
    font-size: 16px;
  }
  .audience__text {
    font-size: 14px;
  }
  .ebrr-cooperation {
    padding: 48px 0;
  }
  .ebrr-cooperation__subtitle {
    margin: 24px 0;
    font-size: 14px;
  }
  .grey-card {
    margin-top: 24px;
    padding: 23px;
  }
  .grey-card .text-light_18 {
    font-size: 14px;
  }
  .grey-card .text-light_18 p {
    margin-bottom: 5px;
  }
  .reviews {
    padding: 48px 0;
  }
  .reviews__list {
    margin-top: 24px;
  }
  .reviews__navigation {
    top: 185px;
    height: 35px;
  }
  .review__info {
    padding: 24px 0 0 0px;
  }
  .review__name {
    font-size: 16px;
  }
  .review__industry {
    padding-right: 100px;
    font-size: 14px;
  }
  .review__industry:before {
    width: 34px;
    height: 29px;
  }
  .review__position {
    margin: 0 0 12px;
    font-size: 14px;
  }
  .review__image {
    height: 350px;
  }
  .review .text-light_18 {
    font-size: 14px;
  }
  .cooperation {
    padding: 48px 0;
  }
  .cooperation__subtitle {
    margin: 16px 0 24px;
    font-size: 14px;
  }
  .form__field {
    width: 70%;
    margin-bottom: 12px;
  }
  .form__button {
    margin-top: 0;
  }
  .form__button .button {
    width: 214px;
    height: 36px;
    margin-top: 12px;
  }
  .contacts {
    margin-top: 160px;
    padding: 0px 0 24px;
    background: #f0f0f0;
  }
  .contacts_section .title {
    display: none;
  }
  .contacts:after {
    height: 72px;
  }
  .contacts_section .title {
    display: none;
  }
  .contacts_section .contacts__map {
    height: 325px;
    padding: 0;
    margin-bottom: -130px;
    transform: translateY(-160px);
  }
  .contacts_page {
    margin-top: 190px;
  }
  .contacts_page .contacts__map {
    height: 325px;
    padding: 0;
  }
  .contacts_page .title {
    margin-bottom: -100px;
    transform: translateY(-100px);
  }
  .contacts_page:after {
    top: -160px;
    height: 45px;
  }
  .contacts__bottom {
    padding: 10px 40px 25px;
    flex-wrap: wrap;
  }
  .contacts__item {
    width: 100%;
    max-width: 100%;
    margin-bottom: 24px;
    padding-left: 15px;
  }
  .contacts__item:last-child {
    margin-bottom: 0;
  }
  .footer__bottom {
    padding: 12px 0;
  }
}
@media only screen and (max-width: 575px) {
  .navigation .navigation__button_prev {
    transform: translateX(0px);
  }
  .navigation .navigation__button_next {
    transform: translateX(0px);
  }
  .title_left-right .initial-letter {
    transform: translate(0%, -50%);
  }
  .mobile-menu .navigation {
    display: block;
  }
  .mobile-menu .navigation__list {
    width: 100%;
    margin-top: 15px;
  }
  .button {
    width: 107px;
    height: 23px;
    font-size: 12px;
  }
  .title {
    font-size: 18px;
    line-height: 18px;
  }
  .subtitle {
    font-size: 14px;
    line-height: 16px;
  }
  .header__logo img {
    width: 128px;
    height: 45px;
  }
  .mainbanner {
    height: 320px;
    min-height: 320px;
  }
  .mainbanner__item-full {
    display: none !important;
  }
  .mainbanner__content,
  .mainbanner .slick-list,
  .mainbanner .slick-track {
    height: 320px;
  }
  .mainbanner .title {
    font-size: 18px;
    line-height: 21px;
  }
  .mainbanner .subtitle {
    margin: 12px 0;
    font-size: 12px;
  }
  .mainbanner__navigation {
    top: unset;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
  }
  .mainbanner__navigation .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0 auto 12px;
  }
  .left-image_reverse .left-image__text .title .initial-letter {
    left: unset;
    right: 0;
    transform: translate(0%, -50%);
  }
  .left-image_reverse .title_left-right:before {
    right: 0;
    left: unset;
    width: 35px;
  }
  .left-image__image img {
    display: block;
    margin: 0 auto;
  }
  .left-image__subtitle {
    font-size: 12px;
  }
  .left-image__text .title {
    padding-left: 48px;
  }
  .left-image__text .title .initial-letter {
    left: -5px;
  }
  .hidden-list__title {
    padding-left: 18px;
    margin-bottom: 0px;
    font-size: 14px;
  }
  .hidden-list__arrow {
    width: 11px;
    height: 6px;
  }
  .key-industries .slick-track {
    display: flex;
    align-items: center;
  }
  .key-industries .key-industries__image {
    max-width: 45px;
    max-height: 40px;
    margin: 0 auto 20px;
  }
  .key-industries__name {
    font-size: 14px;
  }
  .success {
    padding: 24px 0 72px;
  }
  .success__navigation {
    bottom: 36px;
  }
  .success__top {
    padding-bottom: 35px;
  }
  .success__title {
    text-align: right;
    padding-right: 85px;
  }
  .success .navigation .navigation__button_prev,
  .success .navigation .navigation__button_next,
  .success .navigation__button {
    width: 48px;
    height: 48px;
    min-width: 48px;
  }
  .success .navigation .navigation__button_prev:after,
  .success .navigation .navigation__button_next:after,
  .success .navigation__button:after {
    left: 50%;
    transform: translate(-55%, -50%);
  }
  .story__description {
    font-size: 14px;
    line-height: 1.29;
  }
  .story__item-text {
    padding: 0 25px 0 0;
    font-size: 14px;
  }
  .story__img {
    height: 0px;
  }
  .story__img img {
    display: none !important;
  }
  .services__navigation {
    left: 0px;
    right: 0px;
  }
  .team {
    padding: 48px 0 24px;
  }
  .team__container {
    padding: 0 40px;
  }
  .team__navigation {
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
  }
  .team__subtitle {
    margin: 12px 0;
  }
  .team__main {
    max-width: 100%;
    width: 100%;
  }
  .team__additional {
    display: none !important;
  }
  .member__wrapper {
    padding-top: 12px;
  }
  .member__left {
    width: auto;
    margin: 0 auto;
    padding: 0 0 0 50px;
  }
  .member__right {
    margin-top: 12px;
  }
  .member__name {
    font-size: 15px;
    text-align: left;
  }
  .member__position {
    font-size: 12px;
    text-align: left;
  }
  .member__photo {
    width: 180px;
    height: 195px;
    width: 100%;
    height: auto;
    max-width: 360px;
    margin: 0 auto;
  }
  .member__num {
    font-size: 60px;
  }
  .member__num:before {
    top: 12px;
    left: 10px;
  }
  .member__position {
    margin: 5px 0 20px;
  }
  .member__blockquote {
    padding: 12px 0px 0 25px;
    font-size: 14px;
    line-height: 18px;
  }
  .member__blockquote:before {
    top: 12px;
    width: 20px;
    height: 20px;
  }
  .audience {
    padding: 48px 0 24px;
  }
  .audience__container {
    margin: 15px 20px 0;
  }
  .audience__text {
    font-size: 14px;
    line-height: 18px;
  }
  .audience__item img {
    width: 212px;
    height: 120px;
    margin: 0 auto;
  }
  .audience__navigation {
    top: 45px;
    height: 31px;
  }
  .ebrr-cooperation .grey-card__image {
    width: 120px;
    height: 80px;
    margin-bottom: 12px;
  }
  .ebrr-cooperation__subtitle {
    margin: 12px 0 24px;
  }
  .grey-card {
    padding: 18px 24px 24px;
  }
  .grey-card .text-light_18 {
    font-size: 12px;
    line-height: normal;
  }
  .reviews__list {
    margin: 18px 40px;
    width: 100%;
    max-width: calc(100% - 80px);
  }
  .reviews__navigation {
    top: 95px;
    left: 10px;
    right: 10px;
    height: 40px;
  }
  .review__image {
    height: 190px;
    max-width: 100%;
    object-fit: cover;
  }
  .review__image img {
    object-fit: cover;
  }
  .review__industry {
    margin: 6px 0;
    padding-right: 0;
    font-size: 14px;
    line-height: 18px;
  }
  .review__industry:before {
    bottom: 100%;
    top: unset;
    right: 5px;
  }
  .review .text-light_18 {
    line-height: normal;
  }
  .form__field {
    width: 215px;
    height: 36px;
    margin-bottom: 6px;
  }
  .form__field input,
  .form__field textarea {
    min-height: 24px;
    font-size: 14px;
  }
  .form__label {
    left: 0px;
    font-size: 10px;
  }
  .form__button button {
    width: 215px;
    height: 36px;
    font-size: 14px;
  }
  .contacts {
    margin-top: 90px;
    padding-bottom: 0;
  }
  .contacts_section .title {
    display: none;
  }
  .contacts_section .contacts__map {
    height: 180px;
    min-width: calc(100% + 20px);
    margin: 0 -10px -70px;
    transform: translateY(-90px);
  }
  .contacts_page {
    margin-top: 120px;
  }
  .contacts_page .contacts__map {
    height: 180px;
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .contacts_page .title {
    margin: 0px -10px -45px;
    transform: translateY(-60px);
  }
  .contacts_page .contacts__bottom {
    padding-bottom: 30px;
  }
  .contacts_page:after {
    top: -110px;
    height: 35px;
  }
  .contacts__bottom {
    background: #f0f0f0;
  }
  .contacts__text {
    font-size: 14px;
  }
  .footer__text,
  .footer__text a {
    font-size: 12px;
  }
}
.no-scroll {
  overflow: hidden;
}
.breadcrumbs {
  padding: 120px 0 0;
}
.breadcrumbs__item {
  position: relative;
  margin-right: 12px;
}
.breadcrumbs__item:before {
  content: '/';
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(50%, -50%);
  font-size: 16px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #71777f;
}
.breadcrumbs__item a,
.breadcrumbs__item span {
  font-size: 14px;
  font-weight: 300;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #71777f;
}
.breadcrumbs__item:last-child a,
.breadcrumbs__item:last-child span {
  color: #cfcfcf;
}
.breadcrumbs__item:last-child:before {
  display: none;
}
.custom-list {
  padding-left: 25px;
}
.custom-list__item {
  position: relative;
  margin-bottom: 30px;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #71777f;
}
.custom-list__item:before {
  content: '';
  position: absolute;
  top: 6px;
  left: -25px;
  width: 12px;
  height: 12px;
  background: #acd021;
  border-radius: 50%;
}
.title-text {
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
  color: #323a45;
}
.page-wrap {
  width: 100%;
  overflow: hidden;
}
.page404 {
  position: relative;
  padding: 115px 0 340px;
  min-height: 95vh;
}
.page404 .poloska {
  z-index: -1;
}
.page404:before {
  content: '';
  position: absolute;
  bottom: -170px;
  left: 50%;
  z-index: 1;
  display: block;
  width: 1320px;
  height: 626px;
  transform: translate(-50%, 0px);
  background: url("/public/img/404.png") no-repeat center;
}
.page404__number {
  font-size: 200px;
  font-weight: normal;
  text-align: center;
  color: #323a45;
}
.page404__text {
  font-size: 24px;
  font-weight: normal;
  line-height: normal;
  text-align: center;
  color: #323a45;
  text-transform: uppercase;
}
.page404__link {
  margin-top: 75px;
  text-align: center;
}
.page404 .container:last-child {
  position: relative;
  z-index: 2;
}
.list-link {
  padding: 10px 0 70px;
  min-height: calc(100vh - 390px);
}
.list-link_black .list-link__link,
.list-link_black .list-link__title .list-link__counter {
  color: #ffffff;
}
.list-link_black .list-link__title a:before {
  display: none;
}
.list-link_black .list-link__title svg {
  width: 26px;
}
.list-link_black .list-link__title .st0 {
  fill: #FFFFFF;
  transition: 0.25s ease-in-out;
}
.list-link_black .list-link__item:hover .list-link__link,
.list-link_black .list-link__item:hover .list-link__title .list-link__counter {
  color: #000000;
}
.list-link_black .list-link__item:hover .st0 {
  fill: #000000;
  transition: 0.25s ease-in-out;
}
.list-link__column:last-child {
  padding-left: 0;
}
.list-link__item {
  margin: 30px 0;
  overflow: hidden;
}
.list-link__item.visible .list-link__title {
  transform: translateX(0%);
  transition: 1.5s ease-in-out;
}
.list-link__title {
  position: relative;
  padding-right: 100px;
  padding-left: 32px;
  transform: translateX(-100%);
  transition: 0.5s ease-in-out;
}
.list-link__title .list-link__counter,
.list-link__title a {
  font-size: 22px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.55;
  letter-spacing: normal;
  text-align: left;
  color: #71777f;
  transition: 0.25s ease-in-out;
}
.list-link__title a {
  position: relative;
}
.list-link__title a:before {
  content: '';
  position: absolute;
  bottom: 6px;
  left: 100%;
  transform: translate(0%, -50%);
  display: block;
  width: 38px;
  margin-left: 8px;
  height: 8px;
  background: url("/public/img/arrow.svg") no-repeat center center;
  background-size: 100%;
  transition: 0.25s ease-in-out;
}
.list-link__title .list-link__counter {
  font-size: 18px;
}
.list-link__title:hover .list-link__counter,
.list-link__title:hover a {
  color: #7baf33;
  transition: 0.25s ease-in-out;
}
.list-link__title:hover .list-link__counter:before,
.list-link__title:hover a:before {
  transform: translate(25%, -50%);
  transition: 0.25s ease-in-out;
}
.list-link__counter {
  position: absolute;
  transform: rotate(-90deg);
  left: 0;
}
.lang-switcher {
  position: relative;
}
.lang-switcher:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  width: 8px;
  height: 4px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='4'%3E%3Cpath fill='%23323A45' fill-rule='evenodd' d='M.442 0h7.107c.33 0 .576.194.383.385-.155.155-3.279 3.226-3.546 3.488-.176.172-.595.17-.77 0A869.569 869.569 0 0 1 .061.377C-.099.215.078 0 .442 0z'/%3E%3C/svg%3E%0A") no-repeat center center;
  transform: translate(8px, -50%);
}
.lang-switcher__current {
  cursor: pointer;
}
.lang-switcher__list {
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  opacity: 0;
  visibility: hidden;
  trnasform: translateY(25px);
  transition: 0.25s ease-in-out;
}
.lang-switcher__list a {
  color: #878a8f;
  transition: 0.25s ease-in-out;
}
.lang-switcher__list a:hover {
  color: #aacd21;
  transition: 0.25s ease-in-out;
}
.lang-switcher:hover .lang-switcher__list {
  opacity: 1;
  visibility: visible;
  transition: 0.25s ease-in-out;
}
@media only screen and (max-width: 1024px) {
  .page404 {
    padding: 100px 0 240px;
  }
  .page404__number {
    font-size: 140px;
  }
  .page404__link {
    margin-top: 50px;
  }
  .page404:before {
    bottom: -150px;
    width: 990px;
    height: 500px;
    background-size: 100%;
  }
}
@media only screen and (max-width: 991px) {
  .breadcrumbs {
    padding-top: 95px;
  }
  .title-text {
    font-size: 16px;
  }
  .list-link {
    min-height: calc(100vh - 180px);
  }
  .list-link__title {
    padding-right: 45px;
    padding-left: 20px;
  }
  .list-link__title a {
    font-size: 18px;
  }
  .list-link__title a:before {
    bottom: 3px;
  }
  .list-link__title .list-link__counter {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .breadcrumbs {
    padding-top: 65px;
  }
  .breadcrumbs__item {
    line-height: normal;
  }
  .breadcrumbs__item a,
  .breadcrumbs__item span {
    font-size: 10px;
    line-height: normal;
  }
  .page404 {
    display: flex;
    align-items: center;
    min-height: 95vh;
    padding: 70px 0 250px;
  }
  .page404:before {
    bottom: -100px;
    width: 575px;
    height: 400px;
    background-size: 100%;
  }
  .page404__number {
    font-size: 100px;
  }
  .page404__text {
    font-size: 20px;
  }
  .page404__link {
    margin-top: 30px;
  }
  .list-link {
    padding: 20px 0 40px;
  }
  .list-link__column:last-child {
    padding-left: 15px;
  }
  .list-link__item {
    margin: 15px 0;
  }
  .list-link__item:last-child {
    margin-bottom: 0;
  }
  .list-link__title {
    padding-right: 15px;
    transform: translateX(0%);
  }
  .list-link__title a {
    font-size: 16px;
  }
  .list-link__title a:before {
    width: 25px;
    bottom: 1px;
  }
}
@media only screen and (max-width: 575px) {
  .page404 {
    align-items: flex-start;
    padding: 150px 0 250px;
    min-height: auto;
  }
  .page404:before {
    bottom: -50px;
    width: 480px;
    background-size: 100%;
    background-position-y: bottom;
  }
  .page404__number {
    font-size: 100px;
  }
  .page404__text {
    font-size: 20px;
  }
  .page404__link {
    margin-top: 30px;
  }
}
.top-centered {
  position: relative;
  padding-top: 109px;
}
.top-centered .poloska {
  z-index: 0;
}
.top-centered__main {
  position: relative;
  z-index: 5;
}
.top-centered__title {
  max-width: 500px;
  margin: 0 auto 56px;
  text-align: center;
}
.top-centered__image {
  margin: 0 -30px;
  width: calc(100% + 60px);
  height: 444px;
  object-fit: contain;
}
.top-centered__text {
  position: relative;
  padding: 56px 0;
}
.top-centered__text:before,
.top-centered__text:after {
  content: '';
  position: absolute;
  top: -226px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f0f0f0;
}
.top-centered__text:after {
  top: 0;
  background: url("/public/img/services-bg.png") no-repeat center bottom;
  background-size: cover;
  opacity: 0.4;
}
.top-centered__text p,
.top-centered__text li,
.top-centered__text a {
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #71777f;
}
.top-centered:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1px;
  height: 96px;
  background: #aacd21;
  transform: translateX(-75%);
  z-index: 99;
}
.transcending-content {
  max-width: 100vw;
  padding: 70px 0 140px;
  overflow: hidden;
}
.transcending-content .title {
  text-align: right;
  padding-right: 190px;
}
.transcending-content .title_left-right:before {
  right: 68px;
}
.transcending-content .title .initial-letter {
  transform: translate(0%, -50%);
}
.transcending-content__text {
  padding: 30px 190px 0 0;
}
.transcending-content__bottom {
  position: relative;
  z-index: 10;
  padding: 168px 0 156px;
  margin-top: -136px;
}
.transcending-content__bottom:before {
  content: '';
  position: absolute;
  left: calc(50% - 15px);
  top: 0;
  right: 0;
  display: block;
  width: 50vw;
  width: calc(50vw + 15px);
  height: 100%;
  background-color: #f0f0f0;
}
.transcending-content__bottom .slick-list {
  overflow: visible;
}
.transcending-content__bottom .slick-track {
  display: flex;
}
.transcending-content__bottom .slick-slide {
  height: auto;
}
.steps__list {
  position: relative;
  margin: 0 -40px;
  width: 100vw;
  overflow: hidden;
}
.steps__list:after {
  content: '';
  position: absolute;
  right: 100%;
  top: 0;
  bottom: 0;
  z-index: 0;
  width: 50vw;
  background: #ffffff;
}
.steps__list-inner {
  width: 340px;
  margin: 0 -15px;
}
.steps__item {
  max-width: 310px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  border: solid 1px #f0f0f0;
  background-color: #ffffff;
  padding: 50px 40px;
  margin: 0 15px;
}
.steps__item:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #aacd21;
  transform: translateY(-100%);
  transition: 0.5s ease-in-out;
}
.steps__item.animated:before,
.steps__item_first:before {
  transform: translateY(0%);
  transition: 0.5s ease-in-out;
}
.steps__item.animated .steps__img svg path,
.steps__item_first .steps__img svg path {
  fill: #ffffff;
  transition: 0.25s ease-in-out;
}
.steps__item.animated .steps__img:before,
.steps__item_first .steps__img:before {
  background: #ffffff;
  transition: 0.25s ease-in-out;
}
.steps__item.animated .steps__text,
.steps__item_first .steps__text {
  color: #ffffff;
  transition: 0.25s ease-in-out;
}
.steps__img {
  position: relative;
  margin-bottom: 70px;
  text-align: center;
  transition: 0.25s ease-in-out;
}
.steps__img img,
.steps__img svg {
  display: block;
  margin: 0 auto;
  width: 40px;
  height: 35px;
  object-fit: contain;
}
.steps__img:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transition: 0.25s ease-in-out;
  display: block;
  width: 31px;
  height: 1px;
  background: #cfcfcf;
  transform: translate(-50%, 35px);
}
.steps__text {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  text-align: center;
  color: #71777f;
  transition: 0.25s ease-in-out;
}
.steps__navigation {
  position: absolute;
  bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-left: 130px;
}
.steps__navigation .navigation__button_prev {
  margin-right: 0px;
}
.steps__navigation .navigation__button .circle-bg {
  stroke: #d5d5d5;
}
.steps__navigation .navigation__button:hover .circle-bg {
  stroke: #aacd21;
}
.steps__counter {
  height: 30px;
  margin: 0 25px;
}
.timeline__top {
  position: relative;
  padding: 140px 0 115px;
  background: url("/public/img/services/timeline-bg.png") no-repeat center center;
  background-size: cover;
  text-align: center;
}
.timeline__top:before {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  display: block;
  width: 1px;
  height: 98px;
  object-fit: contain;
  background-color: #acd021;
  transform: translate(-50%, -50%);
}
.timeline__bottom {
  position: relative;
  padding-bottom: 84px;
}
.timeline__step {
  display: flex;
  padding: 0 64px;
  margin: 84px 0 0;
}
.timeline__step-image,
.timeline__step-info {
  width: 50%;
  padding: 0 55px;
}
.timeline__step-image img {
  width: 100%;
}
.timeline__step-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 72px;
  margin-bottom: 35px;
  object-fit: contain;
  background-color: #aacd21;
}
.timeline__step-icon .counter {
  display: none;
}
.timeline__step-icon:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  display: block;
  width: 89px;
  height: 1px;
  background-color: #f0f0f0;
  transform: translate(15%, -50%);
}
.timeline__step-info {
  max-width: 435px;
}
.timeline__step-title {
  font-size: 18px;
  font-weight: 300;
  line-height: 1.33;
  text-align: left;
  color: #71777f;
}
.timeline__step:nth-child(even) {
  flex-direction: row-reverse;
}
.timeline__step:nth-child(even) .timeline__step-title {
  text-align: right;
}
.timeline__step:nth-child(even) .timeline__step-info {
  text-align: right;
}
.timeline__step:nth-child(even) .timeline__step-icon {
  margin: 0 0 35px auto;
}
.timeline__step:nth-child(even) .timeline__step-icon:before {
  left: 100%;
  transform: translate(-15%, -50%);
}
.centered-content {
  padding: 195px 0 0px;
}
.centered-content_team {
  position: relative;
  padding-top: 115px;
}
.centered-content_team:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1px;
  height: 98px;
  background-color: #acd021;
}
.centered-content__title {
  text-align: center;
}
.centered-content__text {
  text-align: center;
  margin-top: 20px;
}
.centered-content .title_center .initial-letter {
  color: #f0f0f0;
}
.cases {
  padding: 150px 0 140px;
}
.cases .slick-track {
  display: flex;
}
.cases .slick-slide {
  height: auto;
}
.cases__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 15px;
}
.cases__navigation .circle-bg {
  stroke: #d5d5d5;
}
.cases__navigation .circle-bg:hover {
  stroke: #aacd21;
}
.cases__link {
  position: relative;
  padding-right: 40px;
}
.cases__link:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  width: 30px;
  height: 100%;
  background: url("/public/img/arrow.svg") no-repeat center center;
  background-size: 100%;
  transition: 0.25s ease-in-out;
}
.cases__link:hover:before {
  transform: translate(25%, -50%);
  transition: 0.25s ease-in-out;
}
.cases__link-wrapper {
  text-align: center;
}
.case.slick-initialized.slick-slide,
.case.slick-slide {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}
.case__title,
.case__text {
  max-width: 500px;
  margin: 0 auto;
  font-size: 22px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: center;
  color: #323a45;
  opacity: 0;
  transform: translateX(-25px);
  transition: 0.25s ease-in-out;
}
.case__text {
  margin: 20px auto 40px;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.67;
}
.case__item-result {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  height: 270px;
  padding: 60px 12px 20px;
  background: #aacd21;
  text-align: center;
}
.case__item-result:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f2f2f2;
  transform: translateY(0%);
  transition: 0.25s ease-in-out;
}
.case__item-result:nth-child(even) {
  background: #f2f2f2;
}
.case__item-result:nth-child(even):before {
  background: #aacd21;
  transform: translateY(0%);
  transition: 0.25s ease-in-out;
}
.case__item-result:nth-child(even) .case__item-text {
  color: #71777f;
}
.case__item-img {
  margin-bottom: 24px;
}
.case__item-img img {
  width: 58px;
  height: 58px;
  object-fit: contain;
}
.case__item-text {
  font-size: 16px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.case.animated .case__title,
.case_first .case__title,
.case.animated .case__text,
.case_first .case__text {
  opacity: 1;
  transform: translateX(0px);
  transition: 0.75s ease-in-out;
}
.case.animated .case__item-result:nth-child(odd):before,
.case_first .case__item-result:nth-child(odd):before {
  transform: translateY(-100%);
  transition: 1s ease-in-out;
}
.case.animated .case__item-result:nth-child(even):before,
.case_first .case__item-result:nth-child(even):before {
  transform: translateY(100%);
  transition: 1s ease-in-out;
}
.case.animated-back .case__title,
.case.animated-back .case__text {
  opacity: 0;
  transform: translateX(-10px);
  transition: 0.5s ease-in-out;
}
.case.animated-back .case__item-result:nth-child(odd):before {
  transform: translateY(0%);
  transition: 1s ease-in-out;
}
.case.animated-back .case__item-result:nth-child(even):before {
  transform: translateY(0%);
  transition: 1s ease-in-out;
}
@media only screen and (max-width: 1199px) {
  .centered-content_team {
    padding-top: 75px;
  }
  .centered-content_team:before {
    top: 5px;
    height: 45px;
  }
}
@media only screen and (max-width: 991px) {
  .top-centered {
    padding-top: 90px;
  }
  .top-centered .title {
    margin-bottom: 42px;
  }
  .top-centered__image {
    margin: 0 25px;
    width: calc(100% - 50px);
    max-width: 100%;
    height: 390px;
    object-fit: cover;
  }
  .top-centered__text {
    padding: 40px 0 44px;
  }
  .top-centered__text p,
  .top-centered__text li,
  .top-centered__text a {
    font-size: 16px;
  }
  .top-centered__text:before {
    top: -115px;
  }
  .top-centered:before {
    top: 15px;
    height: 72px;
  }
  .custom-list__item {
    margin-bottom: 15px;
    font-size: 16px;
  }
  .custom-list__item:before {
    top: 5px;
    left: -20px;
    width: 8px;
    height: 8px;
  }
  .transcending-content {
    padding: 50px 0 72px;
  }
  .transcending-content__text {
    padding-top: 16px;
  }
  .transcending-content__bottom {
    padding: 130px 0 120px;
    margin-top: -100px;
  }
  .transcending-content__bottom:before {
    left: 58.33333%;
  }
  .steps__list-inner {
    width: 242px;
  }
  .steps__item {
    padding: 40px 15px 70px;
  }
  .steps__text {
    font-size: 14px;
  }
  .steps__navigation {
    bottom: 36px;
    margin-left: 32px;
  }
  .timeline__top {
    padding: 62px 0 54px;
  }
  .timeline__top:before {
    height: 72px;
  }
  .timeline__step {
    padding: 0 35px;
    margin: 40px 0;
  }
  .timeline__step-info {
    padding: 0 0 0 15px;
  }
  .timeline__step-icon {
    width: 28px;
    height: 35px;
    margin-left: 25px;
    margin-bottom: 12px;
  }
  .timeline__step-icon svg {
    width: 19px;
    height: 19px;
    object-fit: contain;
  }
  .timeline__step-icon:before {
    width: 25px;
  }
  .timeline__step-image {
    padding: 0 15px 0 0;
    text-align: right;
  }
  .timeline__step-image img {
    max-width: 240px;
  }
  .timeline__step-title {
    font-size: 16px;
    line-height: 1.5;
  }
  .timeline__step:nth-child(even) .timeline__step-image {
    padding: 0 0 0 15px;
    text-align: left;
  }
  .timeline__step:nth-child(even) .timeline__step-icon {
    margin-right: 25px;
    margin-bottom: 12px;
  }
  .timeline__step:nth-child(even) .timeline__step-info {
    padding: 0 15px 0 0;
  }
  .services.services_page .services__list {
    margin-left: 0;
    margin-right: 0;
  }
  .services.services_page .services__title {
    padding-left: 20px;
    font-size: 16px;
  }
  .services.services_page .services__counter {
    top: 4px;
    font-size: 12px;
  }
  .centered-content {
    padding: 116px 0 0px;
  }
  .centered-content_team {
    padding-top: 75px;
  }
  .cases {
    padding: 95px 0 70px;
  }
  .case__title {
    font-size: 18px;
  }
  .case__text {
    margin-bottom: 30px;
    font-size: 16px;
  }
  .case__item-result {
    height: 210px;
    padding: 30px 7px 15px;
  }
  .case__item-img {
    margin-bottom: 10px;
  }
  .case__item-img img {
    width: 46px;
    height: 46px;
  }
  .case__item-text {
    font-size: 14px;
  }
}
@media only screen and (max-width: 767px) {
  .top-centered {
    padding-top: 60px;
  }
  .top-centered .title {
    margin-bottom: 22px;
  }
  .top-centered__image {
    display: block;
    width: 310px;
    height: 180px;
    margin: 0 auto;
  }
  .top-centered__text {
    padding: 20px 0;
  }
  .top-centered__text p,
  .top-centered__text li,
  .top-centered__text a {
    font-size: 14px;
    line-height: 1.29;
  }
  .top-centered:before {
    top: 15px;
    height: 24px;
  }
  .transcending-content {
    padding: 46px 0 0;
  }
  .transcending-content .title {
    padding: 0 0px 0 100px;
    margin-right: 100px;
  }
  .transcending-content__text {
    padding: 20px 35px 0 35px;
    font-size: 14px;
    line-height: 1.29;
  }
  .transcending-content__bottom {
    padding: 22px 0 108px;
    margin-top: 0;
  }
  .transcending-content__bottom:before {
    display: none;
  }
  .steps__wrapper {
    margin: 0 auto;
  }
  .steps__list {
    width: 320px;
    margin: 0 auto;
  }
  .steps__list-inner {
    width: 320px;
    margin: 0 auto;
  }
  .steps__item {
    margin: 0 auto;
    padding: 35px 10px;
  }
  .steps__img {
    margin-bottom: 45px;
  }
  .steps__img:before {
    transform: translate(-50%, 22px);
  }
  .steps__navigation {
    bottom: 50px;
    justify-content: center;
    width: 320px;
    margin: 0;
  }
  .steps__navigation .navigation__button {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
  .steps__navigation .navigation__button_next:after {
    left: 24px;
  }
  .steps__navigation .navigation__button_prev:after {
    left: 22px;
  }
  .timeline__top {
    padding: 44px 0;
  }
  .timeline__top:before {
    height: 24px;
  }
  .timeline__bottom {
    padding: 25px 0 45px;
  }
  .timeline__step {
    flex-wrap: wrap;
    padding: 0 15px;
    margin: 0;
  }
  .timeline__step-image,
  .timeline__step-info {
    width: 100%;
    padding: 0;
  }
  .timeline__step-image {
    padding: 0 !important;
  }
  .timeline__step-image img {
    max-width: 100%;
  }
  .timeline__step-icon svg {
    display: none;
  }
  .timeline__step-icon .counter {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    color: #ffffff;
  }
  .timeline__step:nth-child(even) .timeline__step-icon:before {
    left: unset;
    right: 100%;
    transform: translate(15%, -50%);
  }
  .timeline__step:nth-child(even) .timeline__step-info,
  .timeline__step-info {
    position: relative;
    padding-left: 36px;
    padding-top: 12px;
    margin-top: 13px;
  }
  .timeline__step:nth-child(even) .timeline__step-info .timeline__step-icon,
  .timeline__step-info .timeline__step-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }
  .timeline__step:nth-child(even) .timeline__step-title,
  .timeline__step-title {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.29;
    text-align: left;
  }
  .timeline__slider {
    position: relative;
    width: 450px;
    margin: 0 auto;
  }
  .timeline .navigation__button {
    position: absolute;
    top: 50%;
  }
  .timeline .navigation__button svg {
    width: 100%;
    height: 100%;
  }
  .timeline .navigation__button .circle-bg {
    stroke: #878a8f;
  }
  .timeline .navigation__button_prev {
    left: 0;
    transform: translate(-100%, -50%);
  }
  .timeline .navigation__button_prev:after {
    left: 18px;
  }
  .timeline .navigation__button_next {
    right: 0;
    transform: translate(100%, -50%);
  }
  .timeline .navigation__button_next:after {
    left: 20px;
  }
  .services.services_page .services__list {
    margin: 40px 35px 0;
  }
  .centered-content {
    padding: 44px 0 0;
  }
  .centered-content_team {
    padding-top: 65px;
  }
  .cases {
    padding: 59px 0 40px;
  }
  .cases__navigation .navigation__button_prev:after {
    left: 19px;
  }
  .cases__navigation .navigation__button_next:after {
    left: 21px;
  }
  .case {
    max-width: 575px;
    margin: 0 auto;
  }
  .case__title {
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
  }
  .case__text {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 1;
  }
  .case__item-text {
    line-height: 1;
    font-size: 12px;
  }
  .case__item-bottom {
    flex-wrap: wrap;
  }
  .case__item-result {
    width: 25%;
    min-height: 165px;
    height: auto;
    margin: 10px 0;
    padding: 20px 7px;
  }
  .case__item-img img {
    width: 36px;
    height: 36px;
  }
}
@media only screen and (max-width: 575px) {
  .case {
    max-width: 310px;
  }
  .case__item-result {
    width: 50%;
    min-height: 165px;
    height: auto;
    margin: 10px 0;
    padding: 20px 10px;
  }
  .case__item-img img {
    width: 30px;
    height: 30px;
  }
  .timeline__slider {
    width: 90%;
    margin: 0 auto;
  }
  .timeline .navigation__button {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
  .timeline .navigation__button_prev {
    top: 75px;
    left: 10px;
  }
  .timeline .navigation__button_prev:after {
    border-width: 4px;
    left: 12px;
  }
  .timeline .navigation__button_next {
    top: 75px;
    right: 10px;
  }
  .timeline .navigation__button_next:after {
    left: 13px;
    border-width: 4px;
  }
  .steps__navigation {
    width: 200px;
  }
  .steps__list {
    width: 200px;
    margin: 0 auto;
  }
  .steps__list-inner {
    width: 200px;
    margin: 0 auto;
  }
}
.team-basic .slick-track {
  display: flex !important;
}
.team-basic .slick-slide {
  float: none;
}
.team-slider {
  padding-top: 60px;
  padding-bottom: 120px;
}
.team-slider__top .container {
  position: relative;
}
.team-slider__top .container:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 539px;
  height: 143px;
  background: url("/public/img/team-page/team-rain.png") no-repeat center center;
}
.team-slider__top .navigation__button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.team-slider__top .navigation__button_prev {
  left: 30px;
}
.team-slider__top .navigation__button_next {
  right: 30px;
}
.team-slider__top .navigation__button .circle-bg {
  stroke: #d5d5d5;
}
.team-slider__bottom-container {
  position: relative;
}
.team-navigation {
  margin: 0px 90px 130px;
}
.team-navigation__img {
  position: relative;
  width: 262px;
  height: 272px;
  margin: 0 40px;
  cursor: pointer;
  transition: 0.25s ease-in-out;
}
.team-navigation__img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.team-navigation__img:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  transition: 0.5s ease-in-out;
}
.team-navigation__img:hover:before {
  opacity: 0;
  transition: 0.5s ease-in-out;
}
.member-basic__image {
  width: 555px;
  height: 576px;
}
.member-basic__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.member-basic__info {
  padding: 30px 0;
}
.member-basic__name {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #323a45;
}
.member-basic__position {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #323a45;
}
.member-basic__link {
  position: relative;
  display: inline-block;
  margin-top: 25px;
  padding-right: 35px;
}
.member-basic__link:before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0%, -50%);
  width: 30px;
  height: 100%;
  background: url("/public/img/arrow.svg") no-repeat center center;
  background-size: 100%;
  transition: 0.25s ease-in-out;
}
.member-basic__link:hover:before {
  transform: translate(25%, -50%);
  transition: 0.25s ease-in-out;
}
.member-basic__blockquote {
  transform: unset;
  margin: 45px 10px 0 90px;
}
@media only screen and (max-width: 991px) {
  .team-slider {
    padding-top: 40px;
    padding-bottom: 70px;
  }
  .team-slider__top .container:before {
    width: 50%;
    height: 100px;
    background-size: contain;
  }
  .team-slider__top .navigation__button_prev {
    left: 17px;
  }
  .team-slider__top .navigation__button_next {
    right: 17px;
  }
  .team-navigation {
    margin: 0px 45px 80px;
  }
  .team-navigation__img {
    margin: 0 15px;
  }
  .member-basic__image {
    width: 100%;
    height: 500px;
  }
  .member-basic__name {
    font-size: 20px;
  }
  .member-basic__position {
    font-size: 18px;
  }
  .member-basic__link {
    margin-top: 10px;
  }
  .member-basic__blockquote {
    margin: 25px 10px 0 20px;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .member-basic__image {
    width: 320px;
    height: 400px;
    margin: 0 auto;
  }
  .member-basic__blockquote {
    margin: 0px;
  }
  .team-slider__top .navigation__button_prev:after {
    left: 18px;
  }
  .team-slider__top .navigation__button_next:after {
    left: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .team-slider {
    padding: 20px 0;
  }
  .team-navigation {
    margin-bottom: 40px;
  }
  .team-navigation__img:before {
    display: none;
  }
  .member-basic__name {
    font-size: 18px;
  }
  .member-basic__position {
    font-size: 16px;
  }
  .member-basic__image {
    display: none;
  }
  .member-basic__info {
    padding-top: 0;
  }
}
